import actions from '@/vuex/actions/dashboard-mappings'
import getters from '@/vuex/getters/dashboard-mappings'
import {
  GET_DASHBOARD_INDEX_MAPPINGS,
  GET_DASHBOARD_MAPPINGS
} from '@/vuex/types'
import { MutationTree } from 'vuex'

export interface DashboardMappingsState {
  dashboardMappings: {
    column_type: string
    column_code: string
    agg_exec_code: string
  }[]
  dashboardIndexMappings: DashboardIndexMappingState[]
}

export interface DashboardIndexMappingState {
  column_code: string
  dashboard_index_type: string
  exec_code: string
  format: string
}

const initialState: DashboardMappingsState = {
  dashboardMappings: [],
  dashboardIndexMappings: []
}

const mutations: MutationTree<DashboardMappingsState> = {
  [GET_DASHBOARD_MAPPINGS](
    state: DashboardMappingsState,
    dashboardMappings: {
      column_type: string
      column_code: string
      agg_exec_code: string
    }[]
  ): void {
    state.dashboardMappings = dashboardMappings
  },
  [GET_DASHBOARD_INDEX_MAPPINGS](
    state: DashboardMappingsState,
    dashboardIndexMappings: DashboardIndexMappingState[]
  ): void {
    state.dashboardIndexMappings = dashboardIndexMappings
  }
}

export default {
  actions,
  getters,
  mutations,
  state: initialState
}
