import { keyBy } from '@/utils/keyBy'
import { TERM_TO_DATE } from '@/constants/constant'
import {
  DashboardSettingState,
  DashboardSettingItemsState,
  DashboardSettingLayoutState,
  DashboardSettingWidgetState
} from '@/vuex/modules/dashboard-setting'
import { SideNavigationItemState } from '@/vuex/modules/categories'
import States from '@/vuex/modules/state'
import _ from 'lodash'
import { GetterTree } from 'vuex'
import { mapValues } from '@/utils/mapValues'

const getters: GetterTree<DashboardSettingState, States> = {
  dashboardSetting: (s, _g, rs, rg): DashboardSettingItemsState[] => {
    const setting: { [key: string]: DashboardSettingItemsState } = keyBy(
      s.dashboardSetting,
      (o): string => `${o.key}:${o.name}`
    )

    const parentSettingKeyByNameAndKey = <{ [key: string]: DashboardSettingItemsState }>(
      keyBy(rg.sideNavigation, (o): string => `${o.key}:${o.name}`)
    )

    const parentSetting: Record<string, DashboardSettingItemsState> = Object.values(
      mapValues(
        parentSettingKeyByNameAndKey,
        (v, k): DashboardSettingItemsState => setting[k] || { key: v.key, name: v.name, layouts: [] }
      )
    ).reduce((acc, val) => (val.hasOwnProperty('key') ? { ...acc, [val.key]: val } : acc), {})

    const isParent = (nav: SideNavigationItemState): boolean =>
      ['サマリー', 'メディア', 'キャンペーン', 'アドグループ', 'クリエイティブ'].includes(nav.name) ||
      rg.categoryMappings[nav.key] === nav.name

    return (
      _(rg.sideNavigation)
        .map((o): SideNavigationItemState => ({ key: o.key, name: o.name }))
        .concat(
          _(rg.sideNavigation.flatMap((o: { items: SideNavigationItemState[] }): SideNavigationItemState[] => o.items))
            .map((o): SideNavigationItemState => ({ key: o.key, name: o.name }))
            .value()
        )
        // 子カテゴリの設定があれば、それを使う
        // 子カテゴリの設定が無ければ、親カテゴリの設定を使う
        .map(
          (o): DashboardSettingItemsState =>
            setting[`${o.key}:${o.name}`] || {
              key: o.key,
              name: o.name,
              layouts: parentSetting[o.key]?.layouts ?? []
            }
        )
        .map(
          (o): DashboardSettingItemsState => ({
            ...o,
            layouts: o.layouts.map(
              (l): DashboardSettingLayoutState => ({
                ...l,
                widgets: l.widgets.map(
                  (w): DashboardSettingWidgetState => ({
                    ...w,
                    // Termの設定値により、report_dateを変更する
                    dimensions: w.dimensions
                      .join()
                      .replace('report_date', TERM_TO_DATE[rs.condition.term] ?? '')
                      .split(','),
                    // 子カテゴリの場合、key&nameをフィルターに入れる
                    filters: [...w.filters, isParent({ key: o.key, name: o.name }) ? '' : `${o.key}:${o.name}`]
                      .map((v): string => v.replace('campaign', 'cabi_media_name'))
                      .map((v): string => v.replace('adgroup', 'cabi_media_name'))
                      .map((v): string => v.replace('creative', 'cabi_media_name'))
                      .filter((v): boolean => !!v)
                  })
                )
              })
            )
          })
        )
        .keyBy((o): string => `${o.key}:${o.name}`)
        .toPlainObject()
        .value()
    )
  }
}

export default getters
