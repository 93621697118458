import { isEmpty } from '@/utils/isEmpty'
import actions from '@/vuex/actions/categories'
import getters from '@/vuex/getters/categories'
import { GET_CATEGORIES } from '@/vuex/types'
import _ from 'lodash'
import { MutationTree } from 'vuex'

export interface CategoriesState {
  categories: {}[]
}

export interface SideNavigationItemState {
  name: string
  key: string
}

export interface SideNavigationState {
  name: string
  key: string
  items: SideNavigationItemState[]
}

const initialState: CategoriesState = {
  categories: []
}

const mutations: MutationTree<CategoriesState> = {
  [GET_CATEGORIES](state: CategoriesState, categories: {}[]): void {
    state.categories = categories.flatMap((c: Record<string, string>): {} =>
      _(c)
        .keys()
        .filter(
          (k): boolean => k === 'cabi_media_name' || k === 'campaign_id' || (/^category\d+$/.test(k) && !isEmpty(c[k]))
        )
        .reduce((o, k): {} => ({ ...o, [k]: c[k] }), {})
    )
    // .uniqWith(_.isEqual) // CPU使用率が安定したら解除する
  }
}

export default {
  actions,
  getters,
  mutations,
  state: initialState
}
