import condition from '@/vuex/modules/condition'
import dashboardMappings from '@/vuex/modules/dashboard-mappings'
import dashboardSetting from '@/vuex/modules/dashboard-setting'
import dashboardAsyncJobs from '@/vuex/modules/dashboard-async-jobs'
import dashboardAsyncJobsAllocation from '@/vuex/modules/dashboard-async-jobs-allocation'
import dashboards from '@/vuex/modules/dashboards'
import categories from '@/vuex/modules/categories'
import reports from '@/vuex/modules/reports'
import notices from '@/vuex/modules/notices'
import message from '@/vuex/modules/message'
import me from '@/vuex/modules/me'
import motherUsers from '@/vuex/modules/mother-users'
import userDashboards from '@/vuex/modules/user-dashboards'
import colors from '@/vuex/modules/colors'
import userDevice from '@/vuex/modules/user-device'
import filters from '@/vuex/modules/filters'
import targetMediaSettings from '@/vuex/modules/target-media-settings'
import targetAllSettings from '@/vuex/modules/target-all-settings'
import targetCategorySettings from '@/vuex/modules/target-category-settings'
import adTypeMappings from '@/vuex/modules/ad-type-mappings'
import asyncJobHistory from '@/vuex/modules/async-job-history'
import '@/vuex/modules/state'
import { createStore } from 'vuex'

const debug = (import.meta.env && import.meta.env.DEV) ?? process.env.NODE_ENV !== 'production'

export default createStore({
  modules: {
    condition,
    dashboardSetting,
    dashboardMappings,
    dashboardAsyncJobs,
    dashboardAsyncJobsAllocation,
    dashboards,
    categories,
    reports,
    notices,
    message,
    me,
    motherUsers,
    userDashboards,
    colors,
    userDevice,
    filters,
    targetMediaSettings,
    targetAllSettings,
    targetCategorySettings,
    adTypeMappings,
    asyncJobHistory
  },
  strict: debug
})
