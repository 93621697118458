import actions from '@/vuex/actions/filters'
import getters from '@/vuex/getters/filters'
import { GET_FILTERS, CLEAR_FILTERS } from '@/vuex/types'
import { MutationTree } from 'vuex'

export interface FiltersState {
  filters: {}[] | undefined
}

export interface FilterCategories {
  key: string
  value: string
}

export interface FilterCampaigns {
  media_id: number
  media_account_id: string
  campaign_id: string
}

export interface FilterAdgroups {
  media_id: number
  media_account_id: string
  campaign_id: string
  adgroup_id: string
}

export interface FilterAPIRequest {
  filter_id?: number
  filter_name: string
  is_private: boolean
  categories: FilterCategories[]
  campaigns: FilterCampaigns[]
  adgroups: FilterAdgroups[]
}

const initialState: FiltersState = {
  filters: undefined
}

const mutations: MutationTree<FiltersState> = {
  [GET_FILTERS](state: FiltersState, filters: {}[]): void {
    state.filters = filters
  },
  [CLEAR_FILTERS](state: FiltersState): void {
    state.filters = undefined
  }
}

export default {
  actions,
  getters,
  mutations,
  state: initialState
}
