import States from '@/vuex/modules/state'
import { ReportsState, UntaggedReportsState } from '@/vuex/modules/reports'
import { GetterTree } from 'vuex'

const getters: GetterTree<ReportsState, States> = {
  reports: (state: ReportsState): {} => ({
    ...state.reports
  }),
  toggleTableReports: (state: ReportsState): {} => state.toggleTableReports,
  totalReports: (state: ReportsState): {} => state.totalReports,
  untaggedReports: (state: ReportsState): UntaggedReportsState => state.untaggedReports,
  hasUntaggedReports: (state: ReportsState): boolean =>
    Object.keys(state.untaggedReports).some(v => {
      const key = v as 'campaign' | 'adgroup'
      return state.untaggedReports[key].exists
    }),
  isLoadingUntaggedReports: (state: ReportsState): boolean =>
    !Object.keys(state.untaggedReports).every(v => {
      const key = v as 'campaign' | 'adgroup'
      return state.untaggedReports[key].message
    }),
  summaryReportsForAllocation: (state: ReportsState): {}[] => state.summaryReportsForAllocation
}

export default getters
