import { DashboardAsyncJobsState, DashboardAsyncJobState } from '@/vuex/modules/dashboard-async-jobs'
import States from '@/vuex/modules/state'
import { GetterTree } from 'vuex'
import DateUtils, { compareDate } from '@/utils/date'
import dayjs from 'dayjs'
import { DASHBOARD_ASYNC_JOB } from '@/constants/constant'

const getters: GetterTree<DashboardAsyncJobsState, States> = {
  dashboardAsyncJobs: (state: DashboardAsyncJobsState): DashboardAsyncJobState[] => state.dashboardAsyncJobs,
  isClientDeploy: (state: DashboardAsyncJobsState): boolean => {
    const latestReadyUpdatedAt = (
      state.dashboardAsyncJobsClientReport.filter(cr => cr.status === 'READY')[0] || {
        updated_at: '1970-01-01 00:00:00'
      }
    ).updated_at
    return compareDate(
      new DateUtils().setNow(dayjs(latestReadyUpdatedAt)).afterHours(3).formatTime(),
      new DateUtils().formatTime()
    )
  },
  dashboardAsyncJob: (state: DashboardAsyncJobsState): DashboardAsyncJobState | undefined => state.dashboardAsyncJob,
  integrationReportJobs: (state: DashboardAsyncJobsState): DashboardAsyncJobState[] =>
    state.dashboardAsyncJobsGetIntegrationReport,
  clientReportJobs: (state: DashboardAsyncJobsState): DashboardAsyncJobState[] => state.dashboardAsyncJobsClientReport,
  allocationExecuteJobs: (state: DashboardAsyncJobsState): DashboardAsyncJobState[] =>
    state.dashboardAsyncJobsAllocationExecute,
  copiedAllocationExecuteAsyncJob: (state: DashboardAsyncJobsState): DashboardAsyncJobState | undefined =>
    state.copiedAsyncJob && state.copiedAsyncJob.job_type === DASHBOARD_ASYNC_JOB.JOB_TYPE.ALLOCATION_EXECUTE
      ? state.copiedAsyncJob
      : undefined,
  copiedGetIntegrationReportAsyncJob: (state: DashboardAsyncJobsState): DashboardAsyncJobState | undefined =>
    state.copiedAsyncJob && state.copiedAsyncJob.job_type === DASHBOARD_ASYNC_JOB.JOB_TYPE.GET_INTEGRATION_REPORT
      ? state.copiedAsyncJob
      : undefined
}

export default getters
