<template>
  <Dialog :model-value="isShow" max-width="500" @click:outside="$emit('update:isShow', false)">
    <Card>
      <template #title>
        <span>{{ $t('target.kpi_index_setting.delete_message') }}</span>
      </template>
      <template #actions>
        <div class="flex-grow-1" />
        <Button variant="text" @click="$emit('update:isShow', false)">
          <span>{{ $t('common.button.cancel') }}</span>
        </Button>
        <Button variant="elevated" @click="deleteKpi(), $emit('update:isShow', false)">
          <span>{{ $t('common.button.delete') }}</span>
        </Button>
      </template>
    </Card>
  </Dialog>
</template>
<script setup lang="ts">
import { ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { useStore } from 'vuex'

import { deleteKpiAllSetting, deleteKpiCategorySettings, deleteKpiMediaSettings } from '@/application/target/usecase'
import Button from '@/components/atoms/Button/Button.vue'
import Card from '@/components/atoms/Card/Card.vue'
import Dialog from '@/components/atoms/Dialog/Dialog.vue'
import { useCondition } from '@/components/hooks/condition'
import { useMutation } from '@/components/hooks/query'
import type { Target } from '@/domain/model/target/types'
import { SET_MESSAGE } from '@/vuex/types'

type Props = {
  isShow: boolean
  target: Target
}

const props = defineProps<Props>()
const emit = defineEmits<{
  'update:isShow': [v: boolean]
  onSuccess: []
}>()

const store = useStore()
const { t } = useI18n()
const { condition } = await useCondition()

const kpiParams = ref({ dashboard_id: condition.dashboardId.toString() })

const { mutate: deleteKpiAllSettingMutate } = useMutation(deleteKpiAllSetting)(kpiParams)
const { mutate: deleteKpiMediaSettingsMutate } = useMutation(deleteKpiMediaSettings)(kpiParams)
const { mutate: deleteKpiCategorySettingsMutate } = useMutation(deleteKpiCategorySettings)(kpiParams)

const deleteKpi = () => {
  switch (props.target) {
    case 'all':
      deleteKpiAllSettingMutate(undefined, {
        onSuccess: () => {
          // TODO: piniaに移行する
          store.commit(SET_MESSAGE, {
            type: 'green-darken-2',
            text: t('target.kpi_index_setting.delete_message_success')
          })
          emit('onSuccess')
        },
        onError: () => {
          // TODO: piniaに移行する
          store.commit(SET_MESSAGE, {
            type: 'error',
            text: t('target.kpi_index_setting.delete_message_error')
          })
        }
      })
      break
    case 'media':
      deleteKpiMediaSettingsMutate(undefined, {
        onSuccess: () => {
          // TODO: piniaに移行する
          store.commit(SET_MESSAGE, {
            type: 'green-darken-2',
            text: t('target.kpi_index_setting.delete_message_success')
          })
          emit('onSuccess')
        },
        onError: () => {
          // TODO: piniaに移行する
          store.commit(SET_MESSAGE, {
            type: 'error',
            text: t('target.kpi_index_setting.delete_message_error')
          })
        }
      })
      break
    case 'category':
      deleteKpiCategorySettingsMutate(undefined, {
        onSuccess: () => {
          // TODO: piniaに移行する
          store.commit(SET_MESSAGE, {
            type: 'green-darken-2',
            text: t('target.kpi_index_setting.delete_message_success')
          })
          emit('onSuccess')
        },
        onError: () => {
          // TODO: piniaに移行する
          store.commit(SET_MESSAGE, {
            type: 'error',
            text: t('target.kpi_index_setting.delete_message_error')
          })
        }
      })
      break
  }
}
</script>
