export const uniq = <T>(arr: T[] = []): T[] => {
  const seen = new Set()
  const map = new Map<string, T>()

  return arr.filter(item => {
    if (typeof item === 'object' && item !== null) {
      const key = JSON.stringify(item)
      if (map.has(key)) return false
      else {
        map.set(key, item)
        return true
      }
    } else {
      if (seen.has(item)) return false
      else {
        seen.add(item)
        return true
      }
    }
  })
}
