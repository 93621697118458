import { keyBy } from '@/utils/keyBy'
import { mapValues } from '@/utils/mapValues'
import { DashboardMappingsState, DashboardIndexMappingState } from '@/vuex/modules/dashboard-mappings'
import States from '@/vuex/modules/state'
import _ from 'lodash'
import { GetterTree } from 'vuex'

const getters: GetterTree<DashboardMappingsState, States> = {
  dashboardMappings: (state: DashboardMappingsState): {} =>
    Object.assign(
      keyBy(state.dashboardMappings, 'column_code'),
      keyBy<DashboardIndexMappingState>(state.dashboardIndexMappings, 'column_code')
    ),
  categoryMappings: (state: DashboardMappingsState): {} => {
    const filteredDashboardMappings = state.dashboardMappings.filter((m): boolean =>
      /^category\d+$/.test(m.agg_exec_code)
    )
    return mapValues(keyBy(filteredDashboardMappings, 'agg_exec_code'), 'column_code')
  },
  targetCategoryMappings: (state: DashboardMappingsState): string[] =>
    state.dashboardMappings
      .filter((m): boolean => /^category\d+$/.test(m.agg_exec_code) && m.column_code !== m.agg_exec_code)
      .map(m => m.column_code),
  dashboardIndexMappings: (state: DashboardMappingsState): DashboardIndexMappingState[] => state.dashboardIndexMappings,

  customColumns: (state: DashboardMappingsState): string[] =>
    state.dashboardMappings.filter((m): boolean => m.column_type === 'measurement').map(m => m.column_code)
}

export default getters
