import States from '@/vuex/modules/state'
import { GetterTree } from 'vuex'
import { DashboardAsyncJobsAllocationState } from '@/vuex/modules/dashboard-async-jobs-allocation'

const getters: GetterTree<DashboardAsyncJobsAllocationState, States> = {
  dashboardAsyncJobAllocationSummary: (
    state: DashboardAsyncJobsAllocationState
  ): {} => state.estimationAndAllocationSummary,
  dashboardAsyncJobAllocation: (state: DashboardAsyncJobsAllocationState): {} =>
    state.estimationAndAllocation
}

export default getters
