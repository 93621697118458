import { MessageState, MessageItemsState } from '@/vuex/modules/message'
import States from '@/vuex/modules/state'
import { SET_MESSAGE } from '@/vuex/types'
import { ActionContext, ActionTree } from 'vuex'

const actions: ActionTree<MessageState, States> = {
  [SET_MESSAGE](
    context: ActionContext<MessageState, States>,
    message: MessageItemsState
  ): void {
    context.commit(SET_MESSAGE, message)
  }
}

export default actions
