import { ENDPOINT_NOTICES } from '@/constants/constant'
import { handleError, http } from '@/utils/http'
import { NoticesState } from '@/vuex/modules/notices'
import States from '@/vuex/modules/state'
import { GET_NOTICES, PUT_NOTICES, SET_MESSAGE } from '@/vuex/types'
import { ActionContext, ActionTree } from 'vuex'

const actions: ActionTree<NoticesState, States> = {
  [GET_NOTICES](context: ActionContext<NoticesState, States>): Promise<void> {
    return http()
      .get(ENDPOINT_NOTICES)
      .then((res): void => context.commit(GET_NOTICES, res.data.notices))
      .catch((err): void =>
        context.commit(SET_MESSAGE, { text: handleError(err.response) })
      )
  },
  [PUT_NOTICES](
    context: ActionContext<NoticesState, States>,
    payload: { noticeId: number; isRead: boolean }
  ): Promise<void> {
    return http()
      .put(`${ENDPOINT_NOTICES}/${payload.noticeId}`, {
        is_read: payload.isRead
      })
      .then((): Promise<void> => context.dispatch(GET_NOTICES))
      .catch((err): void =>
        context.commit(SET_MESSAGE, { text: handleError(err.response) })
      )
  }
}

export default actions
