import actions from '@/vuex/actions/me'
import getters from '@/vuex/getters/me'
import { GET_ME } from '@/vuex/types'
import { MutationTree } from 'vuex'

export interface MeItemState {
  id: string
  is_external: boolean
  authority: string
  is_agree_terms: boolean
}

export interface MeState {
  me: MeItemState
}

const initialState: MeState = {
  me: {
    id: '',
    is_external: true,
    authority: '',
    is_agree_terms: false
  }
}

const mutations: MutationTree<MeState> = {
  [GET_ME](state: MeState, me: MeItemState): void {
    state.me = me
  }
}

export default {
  actions,
  getters,
  mutations,
  state: initialState
}
