import { getToken, onMessage } from 'firebase/messaging'

import type { NoticeResponse } from '@/domain/model/notice/types'
import { messaging } from '@/firebase'

export const initializeSW = () => {
  onMessage(messaging(), p => {
    const notice = JSON.parse(p.data?.default ?? '{}') as NoticeResponse

    return navigator.serviceWorker.ready
      .then(reg =>
        reg.showNotification(notice.title, {
          body: notice.message,
          data: window.location.origin
        })
      )
      .catch(e => {
        throw new Error(`foreground messaging error: ${e}`)
      })
  })
}

export const requestPermission = async (dispatch: (token: string) => Promise<void>) => {
  await navigator.serviceWorker.register('/messaging-sw.js').then(async sw => {
    await Notification.requestPermission().then(p => {
      if (p === 'granted') {
        getToken(messaging(), { serviceWorkerRegistration: sw })
          .then(t => dispatch(t))
          .catch(e => {
            throw new Error(`messaging get token error: ${e}`)
          })
      }
    })
  })
}
