import { handleError, http } from '@/utils/http'
import States from '@/vuex/modules/state'
import {
  GET_DASHBOARD_ASYNC_JOB_ALLOCATION_SUMMARY,
  GET_DASHBOARD_ASYNC_JOB_ALLOCATION,
  SET_MESSAGE
} from '@/vuex/types'
import { ActionContext, ActionTree } from 'vuex'
import { DashboardAsyncJobsAllocationState } from '@/vuex/modules/dashboard-async-jobs-allocation'
import { ENDPOINT_DASHBOARD_ASYNC_JOBS } from '@/constants/constant'

const actions: ActionTree<DashboardAsyncJobsAllocationState, States> = {
  [GET_DASHBOARD_ASYNC_JOB_ALLOCATION_SUMMARY](
    context: ActionContext<DashboardAsyncJobsAllocationState, States>,
    dashboardAsyncJobId: number
  ): Promise<void> {
    context.commit(GET_DASHBOARD_ASYNC_JOB_ALLOCATION_SUMMARY, [])
    return http()
      .get(`${ENDPOINT_DASHBOARD_ASYNC_JOBS}/${dashboardAsyncJobId}/allocation`)
      .then((res): void =>
        context.commit(
          GET_DASHBOARD_ASYNC_JOB_ALLOCATION_SUMMARY,
          res.data.estimation_and_allocation
        )
      )
      .catch((err): void =>
        context.commit(SET_MESSAGE, { text: handleError(err.response) })
      )
  },
  [GET_DASHBOARD_ASYNC_JOB_ALLOCATION](
    context: ActionContext<DashboardAsyncJobsAllocationState, States>,
    dashboardAsyncJobId: number
  ): Promise<void> {
    context.commit(GET_DASHBOARD_ASYNC_JOB_ALLOCATION, [])
    return http()
      .get(
        `${ENDPOINT_DASHBOARD_ASYNC_JOBS}/${dashboardAsyncJobId}/allocation?group_bys=media`
      )
      .then((res): void =>
        context.commit(
          GET_DASHBOARD_ASYNC_JOB_ALLOCATION,
          res.data.estimation_and_allocation
        )
      )
      .catch((err): void =>
        context.commit(SET_MESSAGE, { text: handleError(err.response) })
      )
  }
}

export default actions
