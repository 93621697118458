import {
  AsyncJobHistoryState,
  SearchConditionState
} from '@/vuex/modules/async-job-history'
import States from '@/vuex/modules/state'
import { GetterTree } from 'vuex'

const getters: GetterTree<AsyncJobHistoryState, States> = {
  searchCondition: (state: AsyncJobHistoryState): SearchConditionState =>
    state.searchCondition
}

export default getters
