import actions from '@/vuex/actions/target-all-settings'
import getters from '@/vuex/getters/target-all-settings'
import { GET_TARGET_ALL_SETTINGS } from '@/vuex/types'
import { MutationTree } from 'vuex'

export interface TargetValues {
  target_value1?: number
  target_value2?: number
  target_value3?: number
  target_value4?: number
  target_value5?: number
  target_value6?: number
  target_value7?: number
  target_value8?: number
  target_value9?: number
  target_value10?: number
  target_value11?: number
  target_value12?: number
  target_value13?: number
  target_value14?: number
  target_value15?: number
  target_value16?: number
  target_value17?: number
  target_value18?: number
  target_value19?: number
  target_value20?: number
}

export interface TargetAllSetting extends TargetValues {
  report_date: string
}

export interface TargetAllSettings {
  targetAllSettings: TargetAllSetting[]
}

const initialState: TargetAllSettings = {
  targetAllSettings: []
}

const mutations: MutationTree<TargetAllSettings> = {
  [GET_TARGET_ALL_SETTINGS](
    state: TargetAllSettings,
    targetAllSettings: TargetAllSetting[]
  ): void {
    state.targetAllSettings = targetAllSettings
  }
}

export default {
  actions,
  getters,
  mutations,
  state: initialState
}
