import actions from '@/vuex/actions/reports'
import getters from '@/vuex/getters/reports'
import {
  GET_REPORTS,
  GET_TOGGLE_TABLE_REPORTS,
  GET_TOTAL_REPORTS,
  CLEAR_REPORTS,
  SET_UNTAGGED_REPORTS,
  GET_SUMMARY_FOR_ALLOCATION
} from '@/vuex/types'
import _ from 'lodash'
import { MutationTree } from 'vuex'

export interface ReportsPayload {
  dimensions: string[]
  filters: string[]
  filterCampaignIds: string[]
  filterAdgroupIds: string[]
  ignoreFilterCampaignIds: string[]
  ignoreFilterAdgroupIds: string[]
  mode: string
  dashboard_index_types: string[]
}

export interface UntaggedReportsItem {
  exists: boolean
  message: string
}
export interface UntaggedReportsState {
  campaign: UntaggedReportsItem
  adgroup: UntaggedReportsItem
}
export interface ReportsState {
  reports: {}
  summaryReportsForAllocation: {}[]
  toggleTableReports: {}
  totalReports: {}
  untaggedReports: UntaggedReportsState
}

const initialState: ReportsState = {
  reports: {},
  summaryReportsForAllocation: [],
  toggleTableReports: {},
  totalReports: {},
  untaggedReports: {
    campaign: { exists: true, message: '' },
    adgroup: { exists: true, message: '' }
  }
}

const mutations: MutationTree<ReportsState> = {
  [GET_REPORTS](
    state: ReportsState,
    payload: { key: string; reports: {}[] }
  ): void {
    state.reports = {
      ...state.reports,
      [payload.key]: payload.reports
    }
  },
  [GET_TOGGLE_TABLE_REPORTS](
    state: ReportsState,
    payload: { key: string; reports: {}[] }
  ): void {
    state.toggleTableReports = {
      ...state.toggleTableReports,
      [payload.key]: payload.reports
    }
  },
  [GET_TOTAL_REPORTS](
    state: ReportsState,
    payload: { key: string; reports: {}[] }
  ): void {
    const isExists = _.toArray(payload.reports[0] || {}).some(
      (v): boolean => !!v
    )

    state.totalReports = {
      ...state.totalReports,
      [payload.key]: isExists ? payload.reports[0] : {}
    }
  },
  [CLEAR_REPORTS](state: ReportsState): void {
    state.reports = {}
    state.toggleTableReports = {}
    state.totalReports = {}
  },
  [SET_UNTAGGED_REPORTS](
    state: ReportsState,
    untaggedReports: UntaggedReportsState
  ): void {
    state.untaggedReports = untaggedReports
  },
  [GET_SUMMARY_FOR_ALLOCATION](state: ReportsState, payload: {}[]): void {
    state.summaryReportsForAllocation = payload
  }
}

export default {
  actions,
  getters,
  mutations,
  state: initialState
}
