import {
  AdTypeMappingsState,
  AdTypeMappingState
} from '@/vuex/modules/ad-type-mappings'
import States from '@/vuex/modules/state'
import { GetterTree } from 'vuex'

const getters: GetterTree<AdTypeMappingsState, States> = {
  adTypeMappings: (state: AdTypeMappingsState): AdTypeMappingState[] =>
    state.adTypeMappings
}

export default getters
