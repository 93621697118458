import actions from '@/vuex/actions/dashboard-async-jobs'
import getters from '@/vuex/getters/dashboard-async-jobs'
import {
  GET_DASHBOARD_ASYNC_JOBS_CLIENT_REPORT,
  GET_DASHBOARD_ASYNC_JOBS_GET_INTEGRATION_REPORT,
  GET_DASHBOARD_ASYNC_JOBS_ALLOCATION_EXECUTE,
  GET_DASHBOARD_ASYNC_JOB,
  GET_DASHBOARD_ASYNC_JOBS,
  SET_COPIED_ASYNC_JOB,
  CLEAR_COPIED_ASYNC_JOB
} from '@/vuex/types'
import { MutationTree } from 'vuex'
import { ALLOCATION, DAY_OF_WEEK } from '@/constants/constant'
import { ReportTemplate } from '@/domain/model/dashboardAsyncJob/types'

export interface DashboardAsyncJobsState {
  dashboardAsyncJobsClientReport: DashboardAsyncJobState[]
  dashboardAsyncJobsGetIntegrationReport: DashboardAsyncJobState[]
  dashboardAsyncJobsAllocationExecute: DashboardAsyncJobState[]
  dashboardAsyncJob?: DashboardAsyncJobState | undefined
  dashboardAsyncJobs: DashboardAsyncJobState[]
  copiedAsyncJob?: DashboardAsyncJobState | undefined
}

export interface DashboardAsyncJobState {
  job_type: string
  arg_json: string
  dashboard_async_job_id: number
  status: string
  created_at: string
  created_by: string
  updated_at: string
}

export interface GetRawDataArgJson {
  from_date: string
  to_date: string
}
export interface ShareAsyncJobResultArgJson {
  dashboard_async_job_id: number
}

export type OutputSettings = { keys: string[]; is_default?: boolean }[]
export type WeekDay = (typeof DAY_OF_WEEK)[number]['value']
export type CustomColumns = {
  column_code: string | null
  is_data_bar: boolean
  index_column_code: string | null
}[]

export interface IntegrationReportArgJson {
  company_name: string
  report_name: string
  password: string
  report_template: ReportTemplate
  from_date: string
  to_date: string
  filter_id: number | null
  sort: { column: string; order: string } | null
  target: string
  average_day: number
  columns: CustomColumns
  output_summary: OutputSettings
  output_daily: OutputSettings
  weekly_setting: {
    output_weekly: OutputSettings
    week_start: WeekDay
  } | null
  monthly_setting: {
    output_monthly: OutputSettings
    month_start: string
  } | null
  output_creative: OutputSettings | null
  filename: string
}

export type OptimizationType = (typeof ALLOCATION.OPTIMIZATION_TYPE)[number]

export interface AllocationArgJson {
  output_from_date: string
  output_to_date: string
  input_from_date: string
  input_to_date: string
  budget_layer?: string
  filter_media_names?: string[]
  target_cost: string
  plan_value: number
  optimization: OptimizationType
  impression_column_name: string
  click_column_name: string
  cost_column_name: string
  kpi_column_name: string
  suppression_keywords: string[]
  filter_target_days: number
  is_input_static_cost: boolean
  average_day: number
  name: string
}

const initialState: DashboardAsyncJobsState = {
  dashboardAsyncJobsClientReport: [],
  dashboardAsyncJobsGetIntegrationReport: [],
  dashboardAsyncJobsAllocationExecute: [],
  dashboardAsyncJob: undefined,
  dashboardAsyncJobs: [],
  copiedAsyncJob: undefined
}

const mutations: MutationTree<DashboardAsyncJobsState> = {
  [GET_DASHBOARD_ASYNC_JOBS_CLIENT_REPORT](
    state: DashboardAsyncJobsState,
    dashboardAsyncJobs: DashboardAsyncJobState[]
  ): void {
    state.dashboardAsyncJobsClientReport = dashboardAsyncJobs
  },
  [GET_DASHBOARD_ASYNC_JOBS_GET_INTEGRATION_REPORT](
    state: DashboardAsyncJobsState,
    dashboardAsyncJobs: DashboardAsyncJobState[]
  ): void {
    state.dashboardAsyncJobsGetIntegrationReport = dashboardAsyncJobs
  },
  [GET_DASHBOARD_ASYNC_JOBS_ALLOCATION_EXECUTE](
    state: DashboardAsyncJobsState,
    dashboardAsyncJobs: DashboardAsyncJobState[]
  ): void {
    state.dashboardAsyncJobsAllocationExecute = dashboardAsyncJobs
  },
  [GET_DASHBOARD_ASYNC_JOBS](state: DashboardAsyncJobsState, dashboardAsyncJobs: DashboardAsyncJobState[]): void {
    state.dashboardAsyncJobs = dashboardAsyncJobs
  },
  [GET_DASHBOARD_ASYNC_JOB](state: DashboardAsyncJobsState, dashboardAsyncJob: DashboardAsyncJobState): void {
    state.dashboardAsyncJob = dashboardAsyncJob
  },
  [SET_COPIED_ASYNC_JOB](state: DashboardAsyncJobsState, copiedAsyncJob: DashboardAsyncJobState): void {
    state.copiedAsyncJob = copiedAsyncJob
  },
  [CLEAR_COPIED_ASYNC_JOB](state: DashboardAsyncJobsState): void {
    state.copiedAsyncJob = undefined
  }
}

export default {
  actions,
  getters,
  mutations,
  state: initialState
}
