import { ENDPOINT_ME } from '@/constants/constant'
import { handleError, http } from '@/utils/http'
import { MeState } from '@/vuex/modules/me'
import States from '@/vuex/modules/state'
import { GET_ME, PUT_ME, SET_MESSAGE } from '@/vuex/types'
import { ActionContext, ActionTree } from 'vuex'

const actions: ActionTree<MeState, States> = {
  [GET_ME](context: ActionContext<MeState, States>): Promise<void> {
    return http()
      .get(ENDPOINT_ME)
      .then((res): void => context.commit(GET_ME, res.data))
      .catch((err): void =>
        context.commit(SET_MESSAGE, { text: handleError(err.response) })
      )
  },
  [PUT_ME](
    context: ActionContext<MeState, States>,
    agreement: { [key: string]: string }
  ): Promise<{} | void> {
    return http()
      .put(ENDPOINT_ME, agreement)
      .then((): Promise<{}> => context.dispatch(GET_ME))
      .catch((err): void =>
        context.commit(SET_MESSAGE, { text: handleError(err.response) })
      )
  }
}

export default actions
