import { isEmpty } from '@/utils/isEmpty'
import { uniqBy } from '@/utils/uniqBy'
import { CategoriesState, SideNavigationState, SideNavigationItemState } from '@/vuex/modules/categories'
import States from '@/vuex/modules/state'
import _ from 'lodash'
import { GetterTree } from 'vuex'

export interface CategoryItem {
  name: string
  itemName: string
  itemKey: string
}

const getters: GetterTree<CategoriesState, States> = {
  mediaNames: (s): string[] => {
    return _(s.categories).map('cabi_media_name').uniq().orderBy().value()
  },
  categories: (s): {}[] => s.categories,
  categoryItems: (s, _g, _rs, rg): { [key: string]: CategoryItem[] } =>
    _(
      uniqBy(
        s.categories.flatMap((c: Record<string, string>): CategoryItem[] =>
          Object.keys(c)
            .filter((k): boolean => /^category\d+$/.test(k) && !isEmpty(c[k]))
            .map(
              (k): CategoryItem => ({
                name: rg.categoryMappings[k],
                itemName: c[k] ?? '',
                itemKey: k
              })
            )
        ),
        (v): string => [v.name, v.itemName, v.itemKey].join()
      )
    )
      .groupBy('name')
      .value(),
  sideNavigation: (s, g, rs): SideNavigationState[] => {
    if (!s.categories.length) return []

    const includeDashboardSettingCategoryKeys = rs.dashboardSetting.dashboardSetting
      .filter(ds => /^category\d+$/.test(ds.key))
      .map(ds => ds.key)

    return [
      { name: 'サマリー', key: 'summary', items: [] },
      {
        name: 'メディア',
        key: 'cabi_media_name',
        items: g.mediaNames.map(
          (m: string): SideNavigationItemState => ({
            name: m,
            key: 'cabi_media_name'
          })
        )
      },
      // FIXME: storeがtypescriptに対応していないためtype assertionで逃げている
      ...(g.categoryItems
        ? Object.entries(g.categoryItems as { [key: string]: CategoryItem[] })
            .filter(ci => ci[1].some(c => includeDashboardSettingCategoryKeys.includes(c.itemKey)))
            .map(([name, items]): SideNavigationState => {
              return {
                name,
                // items[0]がundefinedになることは無い
                // （s.categoriesをフィルターする際に`!_.isEmpty(c[k])`としているため）
                key: items[0]?.itemKey ?? '',
                items: items.map(
                  (c: CategoryItem): SideNavigationItemState => ({
                    name: c.itemName,
                    key: c.itemKey
                  })
                )
              }
            })
        : []),
      {
        name: 'キャンペーン',
        key: 'campaign',
        items: g.mediaNames.map(
          (m: string): SideNavigationItemState => ({
            name: m,
            key: 'campaign'
          })
        )
      },
      {
        name: 'アドグループ',
        key: 'adgroup',
        items: g.mediaNames.map(
          (m: string): SideNavigationItemState => ({
            name: m,
            key: 'adgroup'
          })
        )
      },
      {
        name: 'クリエイティブ',
        key: 'creative',
        items: g.mediaNames.map(
          (m: string): SideNavigationItemState => ({
            name: m,
            key: 'creative'
          })
        )
      }
    ]
  }
}

export default getters
