import { ENDPOINT_AD_TYPE_MAPPINGS } from '@/constants/constant'
import { handleError, http } from '@/utils/http'
import { AdTypeMappingsState } from '@/vuex/modules/ad-type-mappings'
import States from '@/vuex/modules/state'
import { GET_AD_TYPE_MAPPINGS, SET_MESSAGE } from '@/vuex/types'
import { ActionContext, ActionTree } from 'vuex'

const actions: ActionTree<AdTypeMappingsState, States> = {
  [GET_AD_TYPE_MAPPINGS](
    context: ActionContext<AdTypeMappingsState, States>
  ): Promise<void> {
    return http()
      .get(ENDPOINT_AD_TYPE_MAPPINGS)
      .then((res): void =>
        context.commit(GET_AD_TYPE_MAPPINGS, res.data.ad_type_mappings)
      )
      .catch((err): void =>
        context.commit(SET_MESSAGE, { text: handleError(err.response) })
      )
  }
}

export default actions
