import { ColorsState } from '@/vuex/modules/colors'
import States from '@/vuex/modules/state'
import { SET_COLORS_INDEX } from '@/vuex/types'
import { ActionContext, ActionTree } from 'vuex'
import { uniq } from '@/utils/uniq'

const actions: ActionTree<ColorsState, States> = {
  [SET_COLORS_INDEX](context: ActionContext<ColorsState, States>, o: { items: { name: string } }[]): void {
    context.commit(SET_COLORS_INDEX, uniq(o.flatMap(a => a.items).map((item: { name: string }): string => item.name)))
  }
}

export default actions
