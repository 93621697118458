import { AUTH0_ACCESS_TOKEN_COOKIE_NAME } from '@/constants/constant'
import { getCookie, removeCookie } from '@/utils/cookie'
import axios, { AxiosInstance, AxiosResponse } from 'axios'

export function http(): AxiosInstance {
  axios.defaults.headers.common.Authorization = `Bearer ${getCookie(
    AUTH0_ACCESS_TOKEN_COOKIE_NAME
  )}`
  return axios
}

export function httpByNoHeaders(): AxiosInstance {
  axios.defaults.headers.common = {}
  return axios
}

export function handleError(res: AxiosResponse): string {
  console.error(res)

  let errMsg = ''
  switch (res.status) {
    case 401:
      errMsg = getCookie(AUTH0_ACCESS_TOKEN_COOKIE_NAME)
        ? 'API authentication error'
        : ''
      removeCookie(AUTH0_ACCESS_TOKEN_COOKIE_NAME)
      break
    default:
  }

  return errMsg
}
