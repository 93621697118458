import actions from '@/vuex/actions/target-media-settings'
import getters from '@/vuex/getters/target-media-settings'
import { GET_TARGET_MEDIA_SETTINGS } from '@/vuex/types'
import { MutationTree } from 'vuex'
import { TargetValues } from '@/vuex/modules/target-all-settings'

export interface TargetMediaSetting extends TargetValues {
  cabi_media_name: string
  report_date: string
}

export interface TargetMediaSettings {
  targetMediaSettings: TargetMediaSetting[]
}

const initialState: TargetMediaSettings = {
  targetMediaSettings: []
}

const mutations: MutationTree<TargetMediaSettings> = {
  [GET_TARGET_MEDIA_SETTINGS](
    state: TargetMediaSettings,
    targetMediaSettings: TargetMediaSetting[]
  ): void {
    state.targetMediaSettings = targetMediaSettings
  }
}

export default {
  actions,
  getters,
  mutations,
  state: initialState
}
