import actions from '@/vuex/actions/dashboards'
import getters from '@/vuex/getters/dashboards'
import { GET_DASHBOARDS } from '@/vuex/types'
import { MutationTree } from 'vuex'

export type Dashboard = {
  dashboard_id: number
  dashboard_name: string
}

export interface DashboardsState {
  dashboards: Dashboard[]
}

const initialState: DashboardsState = {
  dashboards: []
}

const mutations: MutationTree<DashboardsState> = {
  [GET_DASHBOARDS](state: DashboardsState, dashboards: Dashboard[]): void {
    state.dashboards = dashboards
  }
}

export default {
  actions,
  getters,
  mutations,
  state: initialState
}
