import actions from '@/vuex/actions/target-category-settings'
import getters from '@/vuex/getters/target-category-settings'
import { GET_TARGET_CATEGORY_SETTINGS } from '@/vuex/types'
import { MutationTree } from 'vuex'
import { TargetValues } from '@/vuex/modules/target-all-settings'

export interface TargetCategorySetting extends TargetValues {
  report_date: string
  category_key: string
  category_value: string
}

export interface TargetCategorySettings {
  targetCategorySettings: TargetCategorySetting[]
}

const initialState: TargetCategorySettings = {
  targetCategorySettings: []
}

const mutations: MutationTree<TargetCategorySettings> = {
  [GET_TARGET_CATEGORY_SETTINGS](
    state: TargetCategorySettings,
    targetCategorySettings: TargetCategorySetting[]
  ): void {
    state.targetCategorySettings = targetCategorySettings
  }
}

export default {
  actions,
  getters,
  mutations,
  state: initialState
}
