import { ENDPOINT_TARGET_MEDIA_SETTINGS } from '@/constants/constant'
import { handleError, http } from '@/utils/http'
import States from '@/vuex/modules/state'
import {
  GET_TARGET_MEDIA_SETTINGS,
  PATCH_TARGET_MEDIA_SETTINGS,
  SET_MESSAGE
} from '@/vuex/types'
import { ActionContext, ActionTree } from 'vuex'
import {
  TargetMediaSetting,
  TargetMediaSettings
} from '@/vuex/modules/target-media-settings'

const actions: ActionTree<TargetMediaSettings, States> = {
  [GET_TARGET_MEDIA_SETTINGS](
    context: ActionContext<TargetMediaSettings, States>,
    payload: {
      from_date: string
      to_date: string
    }
  ): Promise<void> {
    return http()
      .get(ENDPOINT_TARGET_MEDIA_SETTINGS, {
        params: {
          dashboard_id: context.rootState.condition.dashboard.dashboard_id,
          from_date: payload.from_date,
          to_date: payload.to_date
        }
      })
      .then((res): void =>
        context.commit(
          GET_TARGET_MEDIA_SETTINGS,
          res.data.target_media_settings
        )
      )
      .catch((err): void =>
        context.commit(SET_MESSAGE, { text: handleError(err.response) })
      )
  },
  [PATCH_TARGET_MEDIA_SETTINGS](
    context: ActionContext<TargetMediaSettings, States>,
    targetMediaSettings: TargetMediaSetting[]
  ): Promise<void> {
    return http()
      .patch(
        ENDPOINT_TARGET_MEDIA_SETTINGS,
        { target_media_settings: targetMediaSettings },
        {
          params: {
            dashboard_id: context.rootState.condition.dashboard.dashboard_id
          }
        }
      )
      .then((): Promise<void> => Promise.resolve())
      .catch((err): Promise<void> => {
        context.commit(SET_MESSAGE, { text: handleError(err.response) })
        return Promise.reject(err)
      })
  }
}

export default actions
