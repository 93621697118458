<template>
  <Card variant="flat" color="light-grey">
    <ChartLayout :is-loading="isLoading" :is-no-data="false">
      <template #chart>
        <DataTable
          :headers="headers"
          :items="kpiItems[target]"
          :group-by="target === 'category' ? [{ key: 'category_key' }] : undefined"
          :max-height="430"
          fixed-header
        >
          <template #group-header="{ item, toggleGroup, isGroupOpen }">
            <tr>
              <td :colspan="headers.length" class="clickable background-color-light-grey" @click="toggleGroup(item)">
                <Icon :name="isGroupOpen(item) ? '$expand' : '$next'" />
                {{ item.value }}
              </td>
            </tr>
          </template>
          <template #item.isActive="{ item }">
            <Switch
              :model-value="item.isActive"
              density="compact"
              @update:model-value="
                v => {
                  item.isActive = v
                  item.cost_index = v ? item.cost_index : ''
                  item.kpi_index = v ? item.kpi_index : ''
                  item.criteria = v ? item.criteria : ''
                }
              "
            />
          </template>
          <template #item.cost_index="{ item }">
            <Select
              v-model="item.cost_index"
              :items="indexSelectItems"
              variant="outlined"
              density="compact"
              :disabled="item.isActive === false"
              hide-details
            />
          </template>
          <template #item.kpi_index="{ item }">
            <Select
              v-model="item.kpi_index"
              :items="indexSelectItems"
              variant="outlined"
              density="compact"
              :disabled="item.isActive === false"
              hide-details
            />
          </template>
          <template #item.criteria="{ item }">
            <Select
              v-model="item.criteria"
              :items="kpiSelectItems"
              variant="outlined"
              density="compact"
              :disabled="item.isActive === false"
              hide-details
            />
          </template>
        </DataTable>
        <div class="d-flex my-5">
          <div class="flex-grow-1" />
          <Button variant="outlined" color="error" size="small" prepend-icon="mdi-delete" @click="isShowDelete = true">
            <span>{{ $t('target.kpi_index_setting.delete_button') }}</span>
          </Button>
          <Suspense>
            <TargetKpiDelete
              v-model:isShow="isShowDelete"
              :target="target"
              @on-success="clearKpiItems(), refetch && refetch()"
            />
          </Suspense>
        </div>
      </template>
    </ChartLayout>
  </Card>
</template>
<script setup lang="ts">
import { computed, ref } from 'vue'
import { useStore } from 'vuex'

import { fetchDashboardMappings } from '@/application/dashboardMapping/usecase'
import { fetchKpiAllSetting, fetchKpiCategorySettings, fetchKpiMediaSettings } from '@/application/target/usecase'
import Button from '@/components/atoms/Button/Button.vue'
import Card from '@/components/atoms/Card/Card.vue'
import DataTable from '@/components/atoms/DataTable/DataTable.vue'
import Icon from '@/components/atoms/Icon/Icon.vue'
import Select, { type SelectItem } from '@/components/atoms/Select/Select.vue'
import Switch from '@/components/atoms/Switch/Switch.vue'
import { useCondition } from '@/components/hooks/condition'
import { useQuery, type Refetch } from '@/components/hooks/query'
import ChartLayout from '@/components/molecules/ChartLayout/ChartLayout.vue'
import TargetKpiDelete from '@/components/organisms/TargetKpiDelete/TargetKpiDelete.vue'
import { VIEW_NAME_MAPPING } from '@/constants/constant'
import { KpiCriteriaLabel } from '@/domain/model/target/constants'
import type { KpiCriteria, Target } from '@/domain/model/target/types'
import type { KpiItems } from '@/pages/TargetSetting.vue'

type Props = {
  target: Target
  kpiItems: KpiItems
  refetch: Refetch | null
}

const { target } = defineProps<Props>()
const emit = defineEmits<{
  'update:kpiItems': [v: KpiItems]
  'update:refetch': [v: Refetch]
}>()

const isShowDelete = ref(false)

const { condition } = await useCondition()

const headers = computed(() => {
  const headers = [
    { title: VIEW_NAME_MAPPING.cost_index, key: 'cost_index' },
    { title: VIEW_NAME_MAPPING.kpi_index, key: 'kpi_index' },
    { title: VIEW_NAME_MAPPING.criteria, key: 'criteria' }
  ]
  if (target === 'media') {
    headers.unshift({ title: 'ON/OFF', key: 'isActive' }, { title: VIEW_NAME_MAPPING.media, key: 'cabi_media_name' })
  }
  if (target === 'category') {
    headers.unshift(
      { title: '', key: 'data-table-group' },
      { title: 'ON/OFF', key: 'isActive' },
      { title: VIEW_NAME_MAPPING.category, key: 'category_value' }
    )
  }
  return headers.map(h => ({ ...h, sortable: false }))
})

const kpiParams = ref({ dashboard_id: condition.dashboardId.toString() })

const { data: dashboardMapping, isPending: dashboardMappingIsPending } = useQuery(fetchDashboardMappings)(kpiParams)

const indexSelectItems = computed(() => [
  { title: '', value: '' },
  ...Object.entries({ ...dashboardMapping.value })
    .filter(([, v]) => v.column_type === 'measurement')
    .map(([k, v]) => ({ title: v.column_code, value: k }))
])

const kpiSelectItems: SelectItem<KpiCriteria | ''>[] = [
  { title: '', value: '' },
  ...Object.entries(KpiCriteriaLabel).map(([k, v]) => ({
    title: v,
    value: k as KpiCriteria
  }))
]

// TODO: @tanstack/vue-queryに移行する
const store = useStore()
const mediaNames = computed<string[]>(() => store.getters.mediaNames)
const categoryItems = computed<Record<string, { name: string; itemName: string; itemKey: string }[]>>(
  () => store.getters.categoryItems
)

const getKpiSettings = async (): Promise<{ isPendingKpi: boolean }> => {
  switch (target) {
    case 'all':
      const {
        data: dataAll,
        isPending: isPendingAll,
        refetch: refetchAll,
        suspense: suspenseAll
      } = useQuery(fetchKpiAllSetting)(kpiParams)
      await suspenseAll()
      emit(
        'update:kpiItems',
        dataAll.value ? { all: [{ ...dataAll.value }] } : { all: [{ cost_index: '', kpi_index: '', criteria: '' }] }
      )
      emit('update:refetch', refetchAll)
      return { isPendingKpi: isPendingAll.value }
    case 'media':
      const {
        data: dataMedia,
        isPending: isPendingMedia,
        refetch: refetchMedia,
        suspense: suspenseMedia
      } = useQuery(fetchKpiMediaSettings)(kpiParams)
      await suspenseMedia()
      emit('update:kpiItems', {
        media: mediaNames.value.map(m => ({
          isActive: dataMedia.value?.some(rm => rm.cabi_media_name === m) ?? false,
          ...(dataMedia.value?.find(rm => rm.cabi_media_name === m) ?? {
            cabi_media_name: m,
            cost_index: '',
            kpi_index: '',
            criteria: ''
          })
        }))
      })
      emit('update:refetch', refetchMedia)
      return { isPendingKpi: isPendingMedia.value }
    case 'category':
      const {
        data: dataCategory,
        isPending: isPendingCategory,
        refetch: refetchCategory,
        suspense: suspenseCategory
      } = useQuery(fetchKpiCategorySettings)(kpiParams)
      await suspenseCategory()
      emit('update:kpiItems', {
        category: Object.values(categoryItems.value)
          .flat()
          .map(c => ({
            isActive:
              dataCategory.value?.some(rm => rm.category_key === c.name && rm.category_value === c.itemName) ?? false,
            ...(dataCategory.value?.find(rm => rm.category_key === c.name && rm.category_value === c.itemName) ?? {
              category_key: c.name,
              category_value: c.itemName,
              cost_index: '',
              kpi_index: '',
              criteria: ''
            })
          }))
      })
      emit('update:refetch', refetchCategory)
      return { isPendingKpi: isPendingCategory.value }
  }
}
const { isPendingKpi } = await getKpiSettings()

const clearKpiItems = () => {
  switch (target) {
    case 'all':
      emit('update:kpiItems', { all: [{ cost_index: '', kpi_index: '', criteria: '' }] })
      break
    case 'media':
      emit('update:kpiItems', {
        media: mediaNames.value.map(m => ({
          isActive: false,
          cabi_media_name: m,
          cost_index: '',
          kpi_index: '',
          criteria: ''
        }))
      })
      break
    case 'category':
      emit('update:kpiItems', {
        category: Object.values(categoryItems.value)
          .flat()
          .map(c => ({
            isActive: false,
            category_key: c.name,
            category_value: c.itemName,
            cost_index: '',
            kpi_index: '',
            criteria: ''
          }))
      })
      break
  }
}

const isLoading = computed(() => isPendingKpi || dashboardMappingIsPending.value)
</script>
