import {
  ENDPOINT_MOTHER_USER_PRODUCT,
  ENDPOINT_MOTHER_USERS
} from '@/constants/constant'
import { handleError, http } from '@/utils/http'
import States from '@/vuex/modules/state'
import { MotherUsersState } from '@/vuex/modules/mother-users'
import {
  ADD_MOTHER_USERS,
  ADD_EXTERNAL_USER_DASHBOARDS,
  GET_NONAME_MOTHER_USERS,
  GET_MOTHER_USERS,
  SET_MESSAGE,
  SET_IS_REGISTERED_MOTHER_USERS,
  UPDATE_MOTHER_USER_PRODUCT,
  UPDATE_MOTHER_USERS
} from '@/vuex/types'
import { ActionContext, ActionTree } from 'vuex'

const actions: ActionTree<MotherUsersState, States> = {
  [GET_NONAME_MOTHER_USERS](
    context: ActionContext<MotherUsersState, States>,
    formData: {
      email: string
      isOwner: boolean
    }
  ): Promise<void | {}> {
    return http()
      .get(ENDPOINT_MOTHER_USERS, {
        params: {
          email: formData.email
        }
      })
      .then(
        (res): Promise<{}> =>
          context.dispatch(UPDATE_MOTHER_USER_PRODUCT, {
            data: res.data,
            isOwner: formData.isOwner
          })
      )
      .catch((err): void =>
        context.commit(SET_MESSAGE, { text: handleError(err) })
      )
  },
  [GET_MOTHER_USERS](
    context: ActionContext<MotherUsersState, States>,
    id: string
  ): Promise<void> {
    return http()
      .get(`${ENDPOINT_MOTHER_USERS}/${id}`)
      .then((res): void => context.commit(GET_MOTHER_USERS, res.data))
      .catch((err): void =>
        context.commit(SET_MESSAGE, { text: handleError(err) })
      )
  },
  [ADD_MOTHER_USERS](
    context: ActionContext<MotherUsersState, States>,
    formData: {
      email: string
      isOwner: boolean
    }
  ): Promise<{} | void> {
    return http()
      .post(ENDPOINT_MOTHER_USERS, { email: formData.email })
      .catch((err): void =>
        context.commit(SET_MESSAGE, { text: handleError(err) })
      )
      .finally(
        (): Promise<{}> => context.dispatch(GET_NONAME_MOTHER_USERS, formData)
      )
  },
  [UPDATE_MOTHER_USERS](
    context: ActionContext<MotherUsersState, States>,
    user: { [key: string]: string }
  ): Promise<{} | void> {
    return http()
      .put(`${ENDPOINT_MOTHER_USERS}/${user.id}`, {
        name: user.name,
        company_name: user.companyName
      })
      .then(
        (): Promise<{}> =>
          context.dispatch(SET_IS_REGISTERED_MOTHER_USERS, true)
      )
      .catch((err): void =>
        context.commit(SET_MESSAGE, { text: handleError(err) })
      )
  },
  [UPDATE_MOTHER_USER_PRODUCT](
    context: ActionContext<MotherUsersState, States>,
    user: {
      data: { [key: string]: {} }
      isOwner: boolean
    }
  ): Promise<{} | void> {
    return http()
      .put(`${ENDPOINT_MOTHER_USER_PRODUCT}/${user.data.external_user_id}`)
      .then(
        (): Promise<{}> => context.dispatch(ADD_EXTERNAL_USER_DASHBOARDS, user)
      )
      .catch((err): void =>
        context.commit(SET_MESSAGE, { text: handleError(err) })
      )
  },
  [SET_IS_REGISTERED_MOTHER_USERS](
    context: ActionContext<MotherUsersState, States>,
    isRegistered: boolean
  ): void {
    context.commit(SET_IS_REGISTERED_MOTHER_USERS, isRegistered)
  }
}

export default actions
