import {
  ENDPOINT_DASHBOARD_INDEX_MAPPINGS,
  ENDPOINT_DASHBOARD_MAPPINGS
} from '@/constants/constant'
import { handleError, http } from '@/utils/http'
import { DashboardMappingsState } from '@/vuex/modules/dashboard-mappings'
import States from '@/vuex/modules/state'
import {
  GET_DASHBOARD_INDEX_MAPPINGS,
  GET_DASHBOARD_MAPPINGS,
  SET_MESSAGE
} from '@/vuex/types'
import { ActionContext, ActionTree } from 'vuex'

const actions: ActionTree<DashboardMappingsState, States> = {
  [GET_DASHBOARD_MAPPINGS](
    context: ActionContext<DashboardMappingsState, States>
  ): Promise<void> {
    return http()
      .get(ENDPOINT_DASHBOARD_MAPPINGS, {
        params: {
          dashboard_id: context.rootState.condition.dashboard.dashboard_id
        }
      })
      .then((res): void =>
        context.commit(GET_DASHBOARD_MAPPINGS, res.data.dashboard_mappings)
      )
      .catch((err): void =>
        context.commit(SET_MESSAGE, { text: handleError(err.response) })
      )
  },
  [GET_DASHBOARD_INDEX_MAPPINGS](
    context: ActionContext<DashboardMappingsState, States>
  ): Promise<void> {
    return http()
      .get(ENDPOINT_DASHBOARD_INDEX_MAPPINGS, {
        params: {
          dashboard_id: context.rootState.condition.dashboard.dashboard_id
        }
      })
      .then((res): void =>
        context.commit(
          GET_DASHBOARD_INDEX_MAPPINGS,
          res.data.dashboard_index_mappings
        )
      )
      .catch((err): void =>
        context.commit(SET_MESSAGE, { text: handleError(err.response) })
      )
  }
}

export default actions
