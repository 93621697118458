import { ENDPOINT_CATEGORIES } from '@/constants/constant'
import { handleError, http } from '@/utils/http'
import { CategoriesState } from '@/vuex/modules/categories'
import States from '@/vuex/modules/state'
import { GET_CATEGORIES, SET_MESSAGE } from '@/vuex/types'
import { ActionContext, ActionTree } from 'vuex'

const actions: ActionTree<CategoriesState, States> = {
  [GET_CATEGORIES](
    context: ActionContext<CategoriesState, States>
  ): Promise<void> {
    return http()
      .get(ENDPOINT_CATEGORIES, {
        params: {
          dashboard_id: context.rootState.condition.dashboard.dashboard_id
        }
      })
      .then((res): void => context.commit(GET_CATEGORIES, res.data.categories))
      .catch((err): void =>
        context.commit(SET_MESSAGE, { text: handleError(err.response) })
      )
  }
}

export default actions
