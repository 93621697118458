import actions from '@/vuex/actions/colors'
import getters from '@/vuex/getters/colors'
import { SET_COLORS_INDEX } from '@/vuex/types'
import { MutationTree } from 'vuex'

export interface ColorsState {
  index: string[]
}

const initialState: ColorsState = {
  index: []
}

const mutations: MutationTree<ColorsState> = {
  [SET_COLORS_INDEX](state: ColorsState, index: string[]): void {
    state.index = index
  }
}

export default {
  actions,
  getters,
  mutations,
  state: initialState
}
