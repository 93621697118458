import actions from '@/vuex/actions/mother-users'
import getters from '@/vuex/getters/mother-users'
import { GET_MOTHER_USERS, SET_IS_REGISTERED_MOTHER_USERS } from '@/vuex/types'
import { MutationTree } from 'vuex'

export interface MotherUsersState {
  motherUsers: {}
  isRegisteredMotherUsers: boolean
}

const initialState: MotherUsersState = {
  motherUsers: {},
  isRegisteredMotherUsers: false
}

const mutations: MutationTree<MotherUsersState> = {
  [GET_MOTHER_USERS](state: MotherUsersState, motherUsers: {}): void {
    state.motherUsers = motherUsers
  },
  [SET_IS_REGISTERED_MOTHER_USERS](
    state: MotherUsersState,
    isRegistered: boolean
  ): void {
    state.isRegisteredMotherUsers = isRegistered
  }
}

export default {
  actions,
  getters,
  mutations,
  state: initialState
}
