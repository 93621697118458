import actions from '@/vuex/actions/ad-type-mappings'
import getters from '@/vuex/getters/ad-type-mappings'
import { GET_AD_TYPE_MAPPINGS } from '@/vuex/types'
import { MutationTree } from 'vuex'

export interface AdTypeMappingState {
  media_id: number
  value: string
  label: string
  display_type: string
  alt_text: string
}

export interface AdTypeMappingsState {
  adTypeMappings: AdTypeMappingState[]
}

const initialState: AdTypeMappingsState = {
  adTypeMappings: []
}

const mutations: MutationTree<AdTypeMappingsState> = {
  [GET_AD_TYPE_MAPPINGS](
    state: AdTypeMappingsState,
    adTypeMappings: AdTypeMappingState[]
  ): void {
    state.adTypeMappings = adTypeMappings
  }
}

export default {
  actions,
  getters,
  mutations,
  state: initialState
}
