import { createVuetify } from 'vuetify'
import 'vuetify/styles'

export default createVuetify({
  theme: {
    defaultTheme: 'light',
    themes: {
      light: {
        colors: {
          primary: '#82be28',
          secondary: '#2d8c3c',
          accent: '#82be28',
          error: '#cd4036',
          info: '#2196f3',
          success: '#82be28',
          warning: '#ffc107'
        }
      }
    }
  }
})
