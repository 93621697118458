import States from '@/vuex/modules/state'
import { UserDashboardsState } from '@/vuex/modules/user-dashboards'
import { GetterTree } from 'vuex'

const getters: GetterTree<UserDashboardsState, States> = {
  internalUserDashboards: (state: UserDashboardsState): [] =>
    state.internalUserDashboards,
  externalUserDashboards: (state: UserDashboardsState): [] =>
    state.externalUserDashboards,
  loginUserDashboard: (state: UserDashboardsState): {} =>
    state.loginUserDashboard,
  internalUserErrorMessage: (state: UserDashboardsState): [] =>
    state.internalUserErrorMessage
}

export default getters
