import actions from '@/vuex/actions/condition'
import getters from '@/vuex/getters/condition'
import {
  SET_DASHBOARD,
  SET_CURRENT,
  SET_FILTER,
  SET_PERIOD,
  SET_TERM,
  SET_CONDITION,
  SET_CREATIVE_SELECTED_ADGROUPS
} from '@/vuex/types'
import { MutationTree } from 'vuex'

export interface ConditionDashboardState {
  dashboard_id: number
  dashboard_name: string
  locale: string
  viewable_from_the_date: string
  domain: string
  monthly_starting_day: number
}

export interface ConditionFilterState {
  categories: string[]
  campaigns: string[]
  adgroups: string[]
}

export interface ConditionPeriodState {
  from: string
  to: string
}

export interface ConditionSelectedAdgroupsState {
  adgroup_id: string
}

export interface ConditionState {
  dashboard: ConditionDashboardState
  current: string
  filter: ConditionFilterState
  term: string
  periodRange: ConditionPeriodState
  selectedAdgroups: ConditionSelectedAdgroupsState[]
}

export interface ConditionQueryState {
  dashboard_id: number
  current?: string
  filter?: string
  term?: string
  period_range?: string
}

const initialState: ConditionState = {
  dashboard: {
    dashboard_id: 0,
    dashboard_name: '',
    locale: '',
    viewable_from_the_date: '',
    domain: '',
    monthly_starting_day: 1
  },
  current: '',
  filter: { categories: [], campaigns: [], adgroups: [] },
  term: '',
  periodRange: { from: '', to: '' },
  selectedAdgroups: []
}

const mutations: MutationTree<ConditionState> = {
  [SET_DASHBOARD](
    state: ConditionState,
    dashboard: ConditionDashboardState
  ): void {
    state.dashboard = dashboard
  },
  [SET_CURRENT](state: ConditionState, current: string): void {
    state.current = current
  },
  [SET_FILTER](state: ConditionState, filter: ConditionFilterState): void {
    state.filter = filter
  },
  [SET_TERM](state: ConditionState, term: string): void {
    state.term = term
  },
  [SET_PERIOD](state: ConditionState, periodRange: ConditionPeriodState): void {
    state.periodRange = periodRange
  },
  [SET_CREATIVE_SELECTED_ADGROUPS](
    state: ConditionState,
    adgroups: ConditionSelectedAdgroupsState[]
  ): void {
    state.selectedAdgroups = adgroups
  },
  [SET_CONDITION](
    state: ConditionState,
    condition: {
      dashboard: ConditionDashboardState
      current: string
      filter: ConditionFilterState
      term: string
      periodRange: ConditionPeriodState
    }
  ): void {
    state.dashboard = condition.dashboard
    state.current = condition.current
    state.filter = condition.filter
    state.term = condition.term
    state.periodRange = condition.periodRange
  }
}

export default {
  actions,
  getters,
  mutations,
  state: initialState
}
