<template>
  <v-snackbar
    v-model="isShow"
    location="top"
    :color="message.type"
    :timeout="message.timeout"
    @update:model-value="clearMessage"
  >
    <v-layout>
      <pre class="message">{{ message.text }}</pre>
      <v-spacer></v-spacer>
      <v-btn
        variant="text"
        @click="
          () => {
            isShow = false
            clearMessage()
          }
        "
      >
        <v-icon size="x-large">mdi-close-circle-outline</v-icon>
      </v-btn>
    </v-layout>
  </v-snackbar>
</template>
<script lang="ts">
import { defineComponent } from 'vue'

import { MessageItemsState } from '@/vuex/modules/message'
import { SET_MESSAGE } from '@/vuex/types'

export default defineComponent({
  name: 'Message',
  data(): {
    isShow: boolean
  } {
    return {
      isShow: false
    }
  },
  computed: {
    message(): MessageItemsState {
      return this.$store.getters.message
    }
  },
  watch: {
    'message.text'(v) {
      this.isShow = !!v
    }
  },
  methods: {
    clearMessage() {
      if (!this.isShow)
        this.$store.dispatch(SET_MESSAGE, { type: this.message.type, text: '' })
    }
  }
})
</script>
<style scoped lang="scss">
.v-layout {
  display: flex;
  align-items: center;

  .message {
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
</style>
