<template>
  <div id="app">
    <v-app>
      <allow-push v-if="isLogin()"></allow-push>
      <message></message>
      <router-view></router-view>
    </v-app>
  </div>
</template>
<script lang="ts">
import GA from '@/components/mixins/GA.vue'
import AllowPush from '@/components/globals/AllowPush.vue'
import Message from '@/components/globals/Message.vue'
import { AUTH0_ACCESS_TOKEN_COOKIE_NAME } from '@/constants/constant'
import { GET_ME } from '@/vuex/types'
import { getCookie } from '@/utils/cookie'
import { initializeSW } from '@/messaging/messaging'
import { MeItemState } from '@/vuex/modules/me'
import { defineComponent } from 'vue'
import './sass/variables.scss'

export default defineComponent({
  name: 'App',
  components: { AllowPush, Message },
  mixins: [GA],
  computed: {
    me(): MeItemState {
      return this.$store.getters.me
    }
  },
  created() {
    this.routerPush()
    this.$store.dispatch(GET_ME)
    this.sendToGa({ eventCategory: 'login' })
    initializeSW()
  },
  updated() {
    this.routerPush()
  },
  methods: {
    isLogin() {
      return !!getCookie(AUTH0_ACCESS_TOKEN_COOKIE_NAME)
    },
    routerPush() {
      if (this.isLogin()) {
        if (location.hash === '#/login') {
          this.$router.push({
            path: '/',
            query: this.$route.query
          })
        }
      } else {
        // OAuthの画面から認証エラーで返ってきた場合、クエリパラメータを付与してloginページにリダイレクト
        this.$router.push({ path: '/login', query: this.$route.query })
      }
    }
  }
})
</script>
<style lang="scss">
@import url('../node_modules/c3/c3.min.css');
</style>
