import {
  TargetCategorySetting,
  TargetCategorySettings
} from '@/vuex/modules/target-category-settings'
import States from '@/vuex/modules/state'
import { GetterTree } from 'vuex'

const getters: GetterTree<TargetCategorySettings, States> = {
  targetCategorySettings: (
    state: TargetCategorySettings
  ): TargetCategorySetting[] => state.targetCategorySettings
}

export default getters
