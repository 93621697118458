import actions from '@/vuex/actions/message'
import getters from '@/vuex/getters/message'
import { SET_MESSAGE } from '@/vuex/types'
import { MutationTree } from 'vuex'

export interface MessageItemsState {
  type?: string
  text: string
  timeout?: number
}

export interface MessageState {
  message: MessageItemsState
}

const initialState: MessageState = {
  message: { type: '', text: '', timeout: -1 }
}

const mutations: MutationTree<MessageState> = {
  [SET_MESSAGE](state: MessageState, message: MessageItemsState): void {
    state.message.type = message.type || 'error'
    state.message.text = message.text
    state.message.timeout = message.timeout || 5000
  }
}

export default {
  actions,
  getters,
  mutations,
  state: initialState
}
