import { ENDPOINT_WIDGET_REPORTS, ENDPOINT_SUMMARY, ENDPOINT_UNTAGGED_REPORTS } from '@/constants/constant'
import { handleError, http } from '@/utils/http'
import { getReportKey } from '@/utils/report'
import States from '@/vuex/modules/state'
import { ConditionState } from '@/vuex/modules/condition'
import { ReportsState } from '@/vuex/modules/reports'
import {
  GET_REPORTS,
  GET_TOGGLE_TABLE_REPORTS,
  GET_TOTAL_REPORTS,
  GET_SUMMARY_REPORTS,
  GET_SUMMARY_FOR_ALLOCATION,
  GET_UNTAGGED_REPORTS,
  SET_MESSAGE,
  SET_UNTAGGED_REPORTS,
  RESET_UNTAGGED_REPORTS
} from '@/vuex/types'
import _ from 'lodash'
import { ActionContext, ActionTree } from 'vuex'
import { uniq } from '@/utils/uniq'

export type SummaryType = '7daysAverage' | 'Estimation' | 'Estimation(MonthlyAverage)'
type SummaryArgs = {
  dashboardId: number
  fromDate: string
  toDate: string
  type: SummaryType
  dimensions?: string[] // groupBys
  filters?: string[]
  filterCampaignIds?: string[]
  filterAdgroupIds?: string[]
  ignoreFilterCampaignIds?: string[]
  ignoreFilterAdgroupIds?: string[]
  mode?: string
  dashboardIndexTypes?: string[]
}
export function setSummaryParams(payload: SummaryArgs): {} {
  return {
    dashboard_id: payload.dashboardId,
    from_date: payload.fromDate,
    to_date: payload.toDate,
    type: payload.type,
    group_bys: (payload.dimensions || []).join(),
    filter_categories: (payload.filters || []).join(),
    filter_campaign_ids: uniq(payload.filterCampaignIds?.map((c: string): string => c.split(':')[2] || '')).join(),
    filter_adgroup_ids: uniq(payload.filterAdgroupIds?.map((c: string): string => c.split(':')[3] || '')).join(),
    ignore_filter_campaign_ids: (payload.ignoreFilterCampaignIds || []).join(),
    ignore_filter_adgroup_ids: (payload.ignoreFilterAdgroupIds || []).join(),
    mode: payload.mode,
    dashboard_index_types: (payload.dashboardIndexTypes || []).join()
  }
}

export function setParams(
  condition: Pick<ConditionState, 'dashboard' | 'filter' | 'periodRange'>,
  payload: {
    dimensions?: string[]
    filters: string[]
    filterCampaignIds?: string[]
    filterAdgroupIds?: string[]
    ignoreFilterCampaignIds?: string[]
    ignoreFilterAdgroupIds?: string[]
    mode: string
    dashboard_index_types: string[]
  },
  otherParams: {} = {}
): {} {
  return {
    dashboard_id: condition.dashboard.dashboard_id,
    from_date: condition.periodRange.from,
    to_date: condition.periodRange.to,
    group_bys: (payload.dimensions || []).join(),
    filter_categories: [...payload.filters, ...condition.filter.categories].join(),
    filter_campaign_ids: uniq(
      (payload.filterCampaignIds || condition.filter.campaigns).map((c: string): string => c.split(':')[2] || '')
    ).join(),
    filter_adgroup_ids: uniq(
      (payload.filterAdgroupIds || condition.filter.adgroups).map((c: string): string => c.split(':')[3] || '')
    ).join(),
    ignore_filter_campaign_ids: (payload.ignoreFilterCampaignIds || []).join(),
    ignore_filter_adgroup_ids: (payload.ignoreFilterAdgroupIds || []).join(),
    mode: payload.mode,
    dashboard_index_types: payload.dashboard_index_types.join(),
    ...otherParams
  }
}

const actions: ActionTree<ReportsState, States> = {
  [GET_REPORTS](
    context: ActionContext<ReportsState, States>,
    payload: {
      dimensions: string[]
      filters: string[]
      filterCampaignIds?: string[]
      filterAdgroupIds?: string[]
      ignoreFilterCampaignIds?: string[]
      ignoreFilterAdgroupIds?: string[]
      mode: string
      dashboard_index_types: string[]
    }
  ): Promise<void> {
    return http()
      .get(ENDPOINT_WIDGET_REPORTS, {
        params: setParams(context.rootState.condition, payload)
      })
      .then((res): void =>
        context.commit(GET_REPORTS, {
          key: getReportKey(payload),
          reports: res.data.widget_reports
        })
      )
      .catch((err): void => context.commit(SET_MESSAGE, { text: handleError(err.response) }))
  },
  [GET_TOGGLE_TABLE_REPORTS](
    context: ActionContext<ReportsState, States>,
    payload: {
      dimensions: string[][]
      filters: string[]
      mode: string
      dashboard_index_types: string[]
    }
  ): Promise<void> {
    return Promise.all(
      payload.dimensions.map((d): Promise<{} | unknown[]> => {
        return http()
          .get(ENDPOINT_WIDGET_REPORTS, {
            params: setParams(context.rootState.condition, {
              ...payload,
              dimensions: d
            })
          })
          .then((res): {} =>
            // FIXME: storeがtypescriptに対応していないためanyで逃げている
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            res.data.widget_reports.map((r: any): {} => ({
              ...r,
              orderByKey: d.flatMap(v => r[v]).join()
            }))
          )
      })
    )
      .then((res): void =>
        context.commit(GET_TOGGLE_TABLE_REPORTS, {
          key: getReportKey({
            ...payload,
            dimensions: (payload.dimensions.length && payload.dimensions[payload.dimensions.length - 1]) || []
          }),
          reports: _(res.flatMap(v => v))
            .orderBy('orderByKey')
            .value()
        })
      )
      .catch((err): void => context.commit(SET_MESSAGE, { text: handleError(err.response) }))
  },
  [GET_TOTAL_REPORTS](
    context: ActionContext<ReportsState, States>,
    payload: {
      totalName: string
      filters: string[]
      mode: string
      dashboard_index_types: string[]
    }
  ): Promise<void> {
    return http()
      .get(ENDPOINT_WIDGET_REPORTS, {
        params: setParams(context.rootState.condition, payload)
      })
      .then((res): void =>
        context.commit(GET_TOTAL_REPORTS, {
          key: getReportKey({ ...payload, dimensions: [payload.totalName] }),
          reports: res.data.widget_reports
        })
      )
      .catch((err): void => context.commit(SET_MESSAGE, { text: handleError(err.response) }))
  },
  [GET_SUMMARY_REPORTS](
    context: ActionContext<ReportsState, States>,
    payload: {
      totalName: string
      filters: string[]
      mode: string
      dashboard_index_types: string[]
    }
  ): Promise<void> {
    return http()
      .get(ENDPOINT_SUMMARY, {
        params: setParams(context.rootState.condition, payload, {
          type: payload.totalName
        })
      })
      .then((res): void =>
        context.commit(GET_TOTAL_REPORTS, {
          key: getReportKey({ ...payload, dimensions: [payload.totalName] }),
          reports: res.data.summary
        })
      )
      .catch((err): void => context.commit(SET_MESSAGE, { text: handleError(err.response) }))
  },
  [GET_UNTAGGED_REPORTS](context: ActionContext<unknown, States>): Promise<void> {
    return http()
      .get(ENDPOINT_UNTAGGED_REPORTS, {
        params: {
          dashboard_id: context.rootState.condition.dashboard.dashboard_id
        }
      })
      .then((res): void => {
        const dashboardName = context.rootState.condition.dashboard.dashboard_name
        const hasUntaggedCampaign = !!res.data.untagged_reports.campaigns.length
        const hasUntaggedAdgroup = !!res.data.untagged_reports.adgroups.length
        const messages: string = [
          hasUntaggedCampaign ? 'Untagged Campaigns exist.' : '',
          hasUntaggedAdgroup ? 'Untagged Adgroups exist.' : ''
        ]
          .filter((s): string => s)
          .join('\n')
        context.commit(
          SET_MESSAGE,
          messages
            ? {
                type: 'warn',
                text: `${dashboardName}\n${messages}`,
                timeout: 60000
              }
            : {
                type: 'info',
                text: `${dashboardName}\nNo untagged reports.`,
                timeout: 60000
              }
        )
        context.commit(SET_UNTAGGED_REPORTS, {
          campaign: {
            exists: hasUntaggedCampaign,
            message: hasUntaggedCampaign ? 'あり （レポート担当者へキャンペーン設定の依頼をしてください）' : 'なし'
          },
          adgroup: {
            exists: hasUntaggedAdgroup,
            message: hasUntaggedAdgroup ? 'あり （レポート担当者へカテゴリ設定の依頼をしてください）' : 'なし'
          }
        })
      })
      .catch((err): void => context.commit(SET_MESSAGE, { text: handleError(err.response) }))
  },
  [RESET_UNTAGGED_REPORTS](context: ActionContext<unknown, States>): void {
    context.commit(SET_UNTAGGED_REPORTS, {
      campaign: { exists: true, message: '' },
      adgroup: { exists: true, message: '' }
    })
  },
  [GET_SUMMARY_FOR_ALLOCATION](context: ActionContext<unknown, States>, payload: SummaryArgs): void {
    http()
      .get(ENDPOINT_SUMMARY, {
        params: setSummaryParams(payload)
      })
      .then((res): void => context.commit(GET_SUMMARY_FOR_ALLOCATION, res.data.summary))
      .catch((err): void => context.commit(SET_MESSAGE, { text: handleError(err.response) }))
  }
}

export default actions
