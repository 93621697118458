import { ENDPOINT_USER_DEVICE } from '@/constants/constant'
import { handleError, http } from '@/utils/http'
import States from '@/vuex/modules/state'
import { POST_USER_DEVICE, SET_MESSAGE } from '@/vuex/types'
import { AxiosResponse } from 'axios'
import { ActionContext, ActionTree } from 'vuex'

const actions: ActionTree<unknown, States> = {
  [POST_USER_DEVICE](context: ActionContext<unknown, States>, token: string): Promise<void | AxiosResponse<unknown>> {
    return http()
      .post(ENDPOINT_USER_DEVICE, { device_token: token })
      .catch((err): void => context.commit(SET_MESSAGE, { text: handleError(err.response) }))
  }
}

export default actions
