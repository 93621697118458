import actions from '@/vuex/actions/notices'
import getters from '@/vuex/getters/notices'
import { GET_NOTICES } from '@/vuex/types'
import { MutationTree } from 'vuex'

export interface NoticesState {
  notices: {}[]
}

const initialState: NoticesState = {
  notices: []
}

const mutations: MutationTree<NoticesState> = {
  [GET_NOTICES](state: NoticesState, notices: {}[]): void {
    state.notices = notices
  }
}

export default {
  actions,
  getters,
  mutations,
  state: initialState
}
