import { TERM } from '@/constants/constant'
import {
  ConditionDashboardState,
  ConditionFilterState,
  ConditionPeriodState,
  ConditionSelectedAdgroupsState,
  ConditionState,
  ConditionQueryState
} from '@/vuex/modules/condition'
import States from '@/vuex/modules/state'
import {
  SET_CREATIVE_SELECTED_ADGROUPS,
  SET_DASHBOARD,
  SET_CURRENT,
  SET_FILTER,
  SET_PERIOD,
  SET_TERM,
  SET_CONDITION,
  SET_MESSAGE,
  CLEAR_REPORTS,
  CLEAR_FILTERS
} from '@/vuex/types'
import { ActionContext, ActionTree } from 'vuex'

const actions: ActionTree<ConditionState, States> = {
  [SET_DASHBOARD](
    context: ActionContext<ConditionState, States>,
    dashboard: ConditionDashboardState
  ): void {
    context.commit(CLEAR_REPORTS)
    context.commit(CLEAR_FILTERS)
    context.commit(SET_DASHBOARD, dashboard)
  },
  [SET_CURRENT](
    context: ActionContext<ConditionState, States>,
    current: string
  ): void {
    context.commit(CLEAR_REPORTS)
    context.commit(SET_CURRENT, current)
  },
  [SET_FILTER](
    context: ActionContext<ConditionState, States>,
    filter: ConditionFilterState
  ): void {
    context.commit(CLEAR_REPORTS)
    context.commit(SET_FILTER, filter)
  },
  [SET_TERM](
    context: ActionContext<ConditionState, States>,
    term: string
  ): void {
    context.commit(CLEAR_REPORTS)
    context.commit(SET_TERM, term)
  },
  [SET_PERIOD](
    context: ActionContext<ConditionState, States>,
    periodRange: ConditionPeriodState
  ): void {
    context.commit(CLEAR_REPORTS)
    context.commit(SET_PERIOD, periodRange)
  },
  [SET_CREATIVE_SELECTED_ADGROUPS](
    context: ActionContext<ConditionState, States>,
    adgroups: ConditionSelectedAdgroupsState[]
  ): void {
    context.commit(SET_CREATIVE_SELECTED_ADGROUPS, adgroups)
  },
  [SET_CONDITION](
    context: ActionContext<ConditionState, States>,
    condition: ConditionQueryState
  ): void {
    const dashboard = context.rootState.dashboards.dashboards.find(
      (d): boolean => d.dashboard_id === condition.dashboard_id
    )
    if (!dashboard) {
      context.commit(SET_MESSAGE, { text: 'ダッシュボードIDが間違っています' })
      return
    }
    if (condition.term && !TERM.includes(condition.term)) {
      context.commit(SET_MESSAGE, {
        text: `${TERM.join(', ')} のいずれかを指定してください`
      })
      return
    }
    context.commit(SET_CONDITION, {
      dashboard,
      current: condition.current || 'summary',
      filter: JSON.parse(
        condition.filter ||
          JSON.stringify({ categories: [], campaigns: [], adgroups: [] })
      ),
      term: condition.term || 'DAILY',
      periodRange: JSON.parse(
        condition.period_range || JSON.stringify({ from: '', to: '' })
      )
    })
  }
}

export default actions
