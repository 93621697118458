import {
  ENDPOINT_DASHBOARD_ASYNC_JOBS,
  DASHBOARD_ASYNC_JOB
} from '@/constants/constant'
import { handleError, http, httpByNoHeaders } from '@/utils/http'
import {
  DashboardAsyncJobsState,
  DashboardAsyncJobState
} from '@/vuex/modules/dashboard-async-jobs'
import States from '@/vuex/modules/state'
import {
  GET_DASHBOARD_ASYNC_JOB,
  GET_DASHBOARD_ASYNC_JOBS,
  GET_DASHBOARD_ASYNC_JOBS_CLIENT_REPORT,
  GET_DASHBOARD_ASYNC_JOBS_FILE,
  GET_DASHBOARD_ASYNC_JOBS_GET_INTEGRATION_REPORT,
  GET_DASHBOARD_ASYNC_JOBS_ALLOCATION_EXECUTE,
  POST_DASHBOARD_ASYNC_JOBS_CLIENT_REPORT,
  POST_DASHBOARD_ASYNC_JOBS_GET_INTEGRATION_REPORT,
  POST_DASHBOARD_ASYNC_JOBS_RAWDATA_DOWNLOAD,
  POST_DASHBOARD_ASYNC_JOBS_SHARE_ASYNC_JOB_RESULT,
  POST_DASHBOARD_ASYNC_JOBS_ALLOCATION_EXECUTE,
  SET_MESSAGE,
  SET_COPIED_ASYNC_JOB,
  CLEAR_COPIED_ASYNC_JOB
} from '@/vuex/types'
import { AxiosPromise } from 'axios'
import { ActionContext, ActionTree } from 'vuex'

const actions: ActionTree<DashboardAsyncJobsState, States> = {
  [GET_DASHBOARD_ASYNC_JOBS_CLIENT_REPORT](
    context: ActionContext<DashboardAsyncJobsState, States>,
    payload: { size: number }
  ): Promise<void> {
    return http()
      .get(ENDPOINT_DASHBOARD_ASYNC_JOBS, {
        params: {
          dashboard_id: context.rootState.condition.dashboard.dashboard_id,
          job_type: DASHBOARD_ASYNC_JOB.JOB_TYPE.CLIENT_REPORT,
          size: payload.size
        }
      })
      .then((res): void =>
        context.commit(
          GET_DASHBOARD_ASYNC_JOBS_CLIENT_REPORT,
          res.data.dashboard_async_jobs
        )
      )
      .catch((err): void =>
        context.commit(SET_MESSAGE, { text: handleError(err.response) })
      )
  },
  [GET_DASHBOARD_ASYNC_JOBS_GET_INTEGRATION_REPORT](
    context: ActionContext<DashboardAsyncJobsState, States>,
    payload: { userId?: string; size?: number }
  ): Promise<void> {
    return http()
      .get(ENDPOINT_DASHBOARD_ASYNC_JOBS, {
        params: {
          dashboard_id: context.rootState.condition.dashboard.dashboard_id,
          job_type: DASHBOARD_ASYNC_JOB.JOB_TYPE.GET_INTEGRATION_REPORT,
          user_id: payload.userId || undefined,
          size: payload.size || undefined
        }
      })
      .then((res): void =>
        context.commit(
          GET_DASHBOARD_ASYNC_JOBS_GET_INTEGRATION_REPORT,
          res.data.dashboard_async_jobs
        )
      )
      .catch((err): void =>
        context.commit(SET_MESSAGE, { text: handleError(err.response) })
      )
  },
  [GET_DASHBOARD_ASYNC_JOBS_ALLOCATION_EXECUTE](
    context: ActionContext<DashboardAsyncJobsState, States>,
    payload: { userId?: string; size?: number }
  ): Promise<void> {
    return http()
      .get(ENDPOINT_DASHBOARD_ASYNC_JOBS, {
        params: {
          dashboard_id: context.rootState.condition.dashboard.dashboard_id,
          job_type: DASHBOARD_ASYNC_JOB.JOB_TYPE.ALLOCATION_EXECUTE,
          size: payload.size || undefined
        }
      })
      .then((res): void =>
        context.commit(
          GET_DASHBOARD_ASYNC_JOBS_ALLOCATION_EXECUTE,
          res.data.dashboard_async_jobs
        )
      )
      .catch((err): void =>
        context.commit(SET_MESSAGE, { text: handleError(err.response) })
      )
  },
  [GET_DASHBOARD_ASYNC_JOBS](
    context: ActionContext<DashboardAsyncJobsState, States>,
    payload: {
      jobType?: string
      status?: string
      userId?: string
      page?: number
      size?: number
    }
  ): Promise<void> {
    return http()
      .get(`${ENDPOINT_DASHBOARD_ASYNC_JOBS}`, {
        params: {
          dashboard_id: context.rootState.condition.dashboard.dashboard_id,
          job_type: payload.jobType || undefined,
          status: payload.status || undefined,
          user_id: payload.userId || undefined,
          page: payload.page ? payload.page - 1 : undefined,
          size: payload.size || undefined
        }
      })
      .then((res): void =>
        context.commit(GET_DASHBOARD_ASYNC_JOBS, res.data.dashboard_async_jobs)
      )
      .catch((err): void =>
        context.commit(SET_MESSAGE, { text: handleError(err.response) })
      )
  },
  [GET_DASHBOARD_ASYNC_JOB](
    context: ActionContext<DashboardAsyncJobsState, States>,
    dashboardAsyncJobId: number
  ): Promise<void> {
    return http()
      .get(`${ENDPOINT_DASHBOARD_ASYNC_JOBS}/${dashboardAsyncJobId}`)
      .then((res): void => context.commit(GET_DASHBOARD_ASYNC_JOB, res.data))
      .catch((err): void =>
        context.commit(SET_MESSAGE, { text: handleError(err.response) })
      )
  },
  [GET_DASHBOARD_ASYNC_JOBS_FILE](
    context: ActionContext<DashboardAsyncJobsState, States>,
    payload: { url: string; filename: string }
  ): Promise<void> {
    return http()
      .get(`${process.env.API_ORIGIN}${payload.url}`)
      .then(
        (res): AxiosPromise<Blob> =>
          httpByNoHeaders().get(res.data, { responseType: 'blob' })
      )
      .then((res): void => {
        const url = res.config.url ? new URL(res.config.url).pathname : ''
        const extensions = url.substring(url.lastIndexOf('.') + 1)
        const blob = new Blob([res.data], {
          type: res.headers['content-type']
        })
        const link = document.createElement('a')
        link.href = window.URL.createObjectURL(blob)
        link.download = `${payload.filename}.${extensions}`
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
      })
      .catch((err): void =>
        context.commit(SET_MESSAGE, { text: handleError(err.response) })
      )
  },
  [POST_DASHBOARD_ASYNC_JOBS_CLIENT_REPORT](
    context: ActionContext<DashboardAsyncJobsState, States>
  ): Promise<void> {
    const data = new FormData()
    data.set('arg_json', JSON.stringify({}))
    return http()
      .post(ENDPOINT_DASHBOARD_ASYNC_JOBS, data, {
        headers: { 'Content-Type': 'multipart/form-data' },
        params: {
          dashboard_id: context.rootState.condition.dashboard.dashboard_id,
          job_type: DASHBOARD_ASYNC_JOB.JOB_TYPE.CLIENT_REPORT
        }
      })
      .then(() => Promise.resolve())
      .catch(err => {
        context.commit(SET_MESSAGE, { text: handleError(err.response) })
        return Promise.reject(new Error('POST dashboard async jobs error'))
      })
  },
  [POST_DASHBOARD_ASYNC_JOBS_RAWDATA_DOWNLOAD](
    context: ActionContext<DashboardAsyncJobsState, States>,
    payload: {
      from_date: string
      to_date: string
    }
  ): Promise<void> {
    const data = new FormData()
    data.set('arg_json', JSON.stringify(payload))
    return http()
      .post(ENDPOINT_DASHBOARD_ASYNC_JOBS, data, {
        headers: { 'Content-Type': 'multipart/form-data' },
        params: {
          dashboard_id: context.rootState.condition.dashboard.dashboard_id,
          job_type: DASHBOARD_ASYNC_JOB.JOB_TYPE.GET_RAWDATA
        }
      })
      .then(() => Promise.resolve())
      .catch(err => {
        context.commit(SET_MESSAGE, { text: handleError(err.response) })
        return Promise.reject(new Error('POST dashboard async jobs error'))
      })
  },
  [POST_DASHBOARD_ASYNC_JOBS_GET_INTEGRATION_REPORT](
    context: ActionContext<DashboardAsyncJobsState, States>,
    argJson
  ): Promise<void> {
    const data = new FormData()
    data.set('arg_json', JSON.stringify(argJson))
    return http()
      .post(ENDPOINT_DASHBOARD_ASYNC_JOBS, data, {
        headers: { 'Content-Type': 'multipart/form-data' },
        params: {
          dashboard_id: context.rootState.condition.dashboard.dashboard_id,
          job_type: DASHBOARD_ASYNC_JOB.JOB_TYPE.GET_INTEGRATION_REPORT
        }
      })
      .then((): Promise<void> => {
        context.commit(CLEAR_COPIED_ASYNC_JOB)
        return context.dispatch(
          GET_DASHBOARD_ASYNC_JOBS_GET_INTEGRATION_REPORT,
          {
            userId: context.rootState.me.me.id,
            size: 1
          }
        )
      })
      .catch((err): Promise<void> => {
        context.commit(SET_MESSAGE, { text: handleError(err.response) })
        return Promise.reject(new Error('POST dashboard async jobs error'))
      })
  },
  [POST_DASHBOARD_ASYNC_JOBS_ALLOCATION_EXECUTE](
    context: ActionContext<DashboardAsyncJobsState, States>,
    argJson
  ): Promise<void> {
    const data = new FormData()
    data.set('arg_json', JSON.stringify(argJson))
    return http()
      .post(ENDPOINT_DASHBOARD_ASYNC_JOBS, data, {
        headers: { 'Content-Type': 'multipart/form-data' },
        params: {
          dashboard_id: context.rootState.condition.dashboard.dashboard_id,
          job_type: DASHBOARD_ASYNC_JOB.JOB_TYPE.ALLOCATION_EXECUTE
        }
      })
      .then(() => {
        context.commit(CLEAR_COPIED_ASYNC_JOB)
        return Promise.resolve()
      })
      .catch(err => {
        context.commit(SET_MESSAGE, { text: handleError(err.response) })
        return Promise.reject(new Error('POST dashboard async jobs error'))
      })
  },
  [POST_DASHBOARD_ASYNC_JOBS_SHARE_ASYNC_JOB_RESULT](
    context: ActionContext<DashboardAsyncJobsState, States>,
    argJson
  ): Promise<void> {
    const data = new FormData()
    data.set('arg_json', JSON.stringify(argJson))
    return http()
      .post(ENDPOINT_DASHBOARD_ASYNC_JOBS, data, {
        headers: { 'Content-Type': 'multipart/form-data' },
        params: {
          dashboard_id: context.rootState.condition.dashboard.dashboard_id,
          job_type: DASHBOARD_ASYNC_JOB.JOB_TYPE.SHARE_ASYNC_JOB_RESULT
        }
      })
      .then(() => Promise.resolve())
      .catch(err => {
        context.commit(SET_MESSAGE, { text: handleError(err.response) })
        return Promise.reject(new Error('POST dashboard async jobs error'))
      })
  },
  [SET_COPIED_ASYNC_JOB](
    context: ActionContext<DashboardAsyncJobsState, States>,
    copiedAsyncJob: DashboardAsyncJobState
  ): void {
    context.commit(SET_COPIED_ASYNC_JOB, copiedAsyncJob)
  }
}

export default actions
