import actions from '@/vuex/actions/async-job-history'
import getters from '@/vuex/getters/async-job-history'
import { MutationTree } from 'vuex'
import { SET_SEARCH_CONDITION } from '@/vuex/types'

export interface SearchConditionState {
  userId?: string
  jobType?: string
  status?: string
}

export interface AsyncJobHistoryState {
  searchCondition: SearchConditionState
}

const initialState: AsyncJobHistoryState = {
  searchCondition: {}
}

const mutations: MutationTree<AsyncJobHistoryState> = {
  [SET_SEARCH_CONDITION](
    state: AsyncJobHistoryState,
    condition: {
      jobType?: string
      userId?: string
      status?: string
    }
  ): void {
    state.searchCondition = condition
  }
}

export default {
  actions,
  getters,
  mutations,
  state: initialState
}
