import actions from '@/vuex/actions/dashboard-setting'
import getters from '@/vuex/getters/dashboard-setting'
import { GET_DASHBOARD_SETTING } from '@/vuex/types'
import { MutationTree } from 'vuex'

export interface DashboardSettingWidgetState {
  widget_type: string
  widget_name: string
  widget_size: number
  dimensions: string[]
  measurements: string[]
  visuals: string[]
  range: number
  sort: string
  sort_order: 'asc' | 'desc'
  top: number
  totals: string[]
  filters: string[]
  mode: string
  dashboard_index_types: string[]
  c3Size: { width?: number; height?: number }
  isFlat: boolean
  kpi: string
}

export interface DashboardSettingLayoutState {
  widgets: DashboardSettingWidgetState[]
}

export interface DashboardSettingItemsState {
  key: string
  name: string
  layouts: DashboardSettingLayoutState[]
}

export interface DashboardSettingState {
  dashboardSetting: DashboardSettingItemsState[]
}

const initialState: DashboardSettingState = {
  dashboardSetting: []
}

const mutations: MutationTree<DashboardSettingState> = {
  [GET_DASHBOARD_SETTING](
    state: DashboardSettingState,
    dashboardSetting: {
      key: string
      name: string
      layouts: DashboardSettingLayoutState[]
    }[]
  ): void {
    state.dashboardSetting = dashboardSetting
  }
}

export default {
  actions,
  getters,
  mutations,
  state: initialState
}
