import States from '@/vuex/modules/state'
import { SET_SEARCH_CONDITION } from '@/vuex/types'
import { ActionContext, ActionTree } from 'vuex'
import { AsyncJobHistoryState } from '@/vuex/modules/async-job-history'

const actions: ActionTree<AsyncJobHistoryState, States> = {
  [SET_SEARCH_CONDITION](
    context: ActionContext<AsyncJobHistoryState, States>,
    payload: { jobType?: string; status?: string; userId?: string }
  ): void {
    context.commit(SET_SEARCH_CONDITION, {
      userId: payload.userId ?? context.rootState.me.me.id,
      jobType: payload.jobType,
      status: payload.status
    })
  }
}

export default actions
