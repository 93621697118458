import actions from '@/vuex/actions/dashboard-async-jobs-allocation'
import getters from '@/vuex/getters/dashboard-async-jobs-allocation'
import {
  GET_DASHBOARD_ASYNC_JOB_ALLOCATION_SUMMARY,
  GET_DASHBOARD_ASYNC_JOB_ALLOCATION
} from '@/vuex/types'
import { MutationTree } from 'vuex'

export interface EstimationAndAllocationState {
  cabi_media_name?: string
}

export interface DashboardAsyncJobsAllocationState {
  estimationAndAllocationSummary: EstimationAndAllocationState[]
  estimationAndAllocation: EstimationAndAllocationState[]
}

const initialState: DashboardAsyncJobsAllocationState = {
  estimationAndAllocationSummary: [],
  estimationAndAllocation: []
}

const mutations: MutationTree<DashboardAsyncJobsAllocationState> = {
  [GET_DASHBOARD_ASYNC_JOB_ALLOCATION_SUMMARY](
    state: DashboardAsyncJobsAllocationState,
    estimationAndAllocation: []
  ): void {
    state.estimationAndAllocationSummary = estimationAndAllocation
  },
  [GET_DASHBOARD_ASYNC_JOB_ALLOCATION](
    state: DashboardAsyncJobsAllocationState,
    estimationAndAllocation: []
  ): void {
    state.estimationAndAllocation = estimationAndAllocation
  }
}

export default {
  actions,
  getters,
  mutations,
  state: initialState
}
