import {
  TargetMediaSetting,
  TargetMediaSettings
} from '@/vuex/modules/target-media-settings'
import States from '@/vuex/modules/state'
import { GetterTree } from 'vuex'

const getters: GetterTree<TargetMediaSettings, States> = {
  targetMediaSettings: (state: TargetMediaSettings): TargetMediaSetting[] =>
    state.targetMediaSettings
}

export default getters
