import States from '@/vuex/modules/state'
import { MotherUsersState } from '@/vuex/modules/mother-users'
import { GetterTree } from 'vuex'

const getters: GetterTree<MotherUsersState, States> = {
  motherUsers: (state: MotherUsersState): {} => state.motherUsers,
  isRegisteredMotherUsers: (state: MotherUsersState): boolean =>
    state.isRegisteredMotherUsers
}

export default getters
