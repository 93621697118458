import { ENDPOINT_TARGET_ALL_SETTINGS } from '@/constants/constant'
import { handleError, http } from '@/utils/http'
import States from '@/vuex/modules/state'
import {
  GET_TARGET_ALL_SETTINGS,
  PATCH_TARGET_ALL_SETTINGS,
  SET_MESSAGE
} from '@/vuex/types'
import { ActionContext, ActionTree } from 'vuex'
import { TargetAllSetting } from '@/vuex/modules/target-all-settings'

const actions: ActionTree<TargetAllSetting, States> = {
  [GET_TARGET_ALL_SETTINGS](
    context: ActionContext<TargetAllSetting, States>,
    payload: {
      from_date: string
      to_date: string
    }
  ): Promise<void> {
    return http()
      .get(ENDPOINT_TARGET_ALL_SETTINGS, {
        params: {
          dashboard_id: context.rootState.condition.dashboard.dashboard_id,
          from_date: payload.from_date,
          to_date: payload.to_date
        }
      })
      .then((res): void =>
        context.commit(GET_TARGET_ALL_SETTINGS, res.data.target_all_settings)
      )
      .catch((err): void =>
        context.commit(SET_MESSAGE, { text: handleError(err.response) })
      )
  },
  [PATCH_TARGET_ALL_SETTINGS](
    context: ActionContext<TargetAllSetting, States>,
    targetAllSettings: TargetAllSetting[]
  ): Promise<void> {
    return http()
      .patch(
        ENDPOINT_TARGET_ALL_SETTINGS,
        { target_all_settings: targetAllSettings },
        {
          params: {
            dashboard_id: context.rootState.condition.dashboard.dashboard_id
          }
        }
      )
      .then(() => Promise.resolve())
      .catch(err => {
        context.commit(SET_MESSAGE, { text: handleError(err.response) })
        return Promise.reject(err)
      })
  }
}

export default actions
