<template>
  <v-snackbar v-model="isShow" location="bottom right" multi-line vertical color="info" :timeout="-1">
    <span>{{ $t('globals.push.allowPushText1') }}</span>
    <span>{{ $t('globals.push.allowPushText2') }}</span>
    <span>{{ $t('globals.push.allowPushText3') }}</span>
    <span>{{ $t('globals.push.allowPushText4') }}</span>
    <div class="text-right">
      <v-btn variant="flat" color="info" @click="setMessaging()">OK</v-btn>
      <v-btn class="ml-3" variant="flat" color="info" @click="isShow = false">Cancel</v-btn>
    </div>
  </v-snackbar>
</template>
<script lang="ts">
import { defineComponent } from 'vue'
import { requestPermission } from '@/messaging/messaging'
import { POST_USER_DEVICE } from '@/vuex/types'

export default defineComponent({
  name: 'AllowPush',
  data(): {
    isShow: boolean
  } {
    return {
      isShow: false
    }
  },
  mounted() {
    if (Notification.permission === 'default') this.isShow = true
  },
  methods: {
    setMessaging() {
      this.isShow = false
      requestPermission(token => this.$store.dispatch(POST_USER_DEVICE, token))
    }
  }
})
</script>
