import { ENDPOINT_DASHBOARDS } from '@/constants/constant'
import { handleError, http } from '@/utils/http'
import { ConditionDashboardState } from '@/vuex/modules/condition'
import { DashboardsState } from '@/vuex/modules/dashboards'
import States from '@/vuex/modules/state'
import {
  GET_DASHBOARDS,
  PUT_DASHBOARDS,
  SET_DASHBOARD,
  SET_MESSAGE
} from '@/vuex/types'
import { ActionContext, ActionTree } from 'vuex'

const actions: ActionTree<DashboardsState, States> = {
  [GET_DASHBOARDS](
    context: ActionContext<DashboardsState, States>
  ): Promise<void> {
    return http()
      .get(ENDPOINT_DASHBOARDS)
      .then((res): void => context.commit(GET_DASHBOARDS, res.data.dashboards))
      .catch((err): void =>
        context.commit(SET_MESSAGE, { text: handleError(err.response) })
      )
  },
  [PUT_DASHBOARDS](
    context: ActionContext<DashboardsState, States>,
    dashboard: ConditionDashboardState
  ): Promise<void> {
    return http()
      .put(`${ENDPOINT_DASHBOARDS}/${dashboard.dashboard_id}`, dashboard)
      .then((): Promise<void> => context.dispatch(SET_DASHBOARD, dashboard))
      .catch((err): void =>
        context.commit(SET_MESSAGE, { text: handleError(err.response) })
      )
  }
}

export default actions
