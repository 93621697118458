import {
  TargetAllSetting,
  TargetAllSettings
} from '@/vuex/modules/target-all-settings'
import States from '@/vuex/modules/state'
import { GetterTree } from 'vuex'

const getters: GetterTree<TargetAllSettings, States> = {
  targetAllSettings: (state: TargetAllSettings): TargetAllSetting[] =>
    state.targetAllSettings
}

export default getters
