import { ENDPOINT_USER_DASHBOARDS } from '@/constants/constant'
import { handleError, http } from '@/utils/http'
import DateUtils from '@/utils/date'
import States from '@/vuex/modules/state'
import { UserDashboardsState } from '@/vuex/modules/user-dashboards'
import {
  ADD_EXTERNAL_USER_DASHBOARDS,
  GET_LOGIN_USER_DASHBOARD,
  GET_USER_DASHBOARDS,
  GET_INTERNAL_USER_DASHBOARDS,
  GET_EXTERNAL_USER_DASHBOARDS,
  SET_MESSAGE,
  SET_INTERNAL_USER_ERROR_MESSAGE,
  UPDATE_USER_DASHBOARDS
} from '@/vuex/types'
import { ActionContext, ActionTree } from 'vuex'

const actions: ActionTree<UserDashboardsState, States> = {
  [GET_USER_DASHBOARDS](
    context: ActionContext<UserDashboardsState, States>,
    isExternal: boolean
  ): Promise<void> {
    return http()
      .get(ENDPOINT_USER_DASHBOARDS, {
        params: {
          dashboard_id: context.rootState.condition.dashboard.dashboard_id,
          is_external: isExternal
        }
      })
      .then((res): void => {
        context.commit(
          isExternal
            ? GET_EXTERNAL_USER_DASHBOARDS
            : GET_INTERNAL_USER_DASHBOARDS,
          res.data.user_dashboards
        )
      })
      .catch((err): void => {
        context.commit(SET_MESSAGE, { text: handleError(err) })
      })
  },
  [GET_LOGIN_USER_DASHBOARD](
    context: ActionContext<UserDashboardsState, States>
  ): Promise<void> {
    return http()
      .get(
        `${ENDPOINT_USER_DASHBOARDS}/${context.rootState.me.me.id}/${context.rootState.condition.dashboard.dashboard_id}`
      )
      .then((res): void => context.commit(GET_LOGIN_USER_DASHBOARD, res.data))
      .catch((err): void => {
        if (
          context.rootState.me.me.authority !== 'SUPER_USER' ||
          err.response.status !== 404
        ) {
          context.commit(SET_MESSAGE, { text: handleError(err) })
        }
        context.commit(GET_LOGIN_USER_DASHBOARD, {})
      })
  },
  [ADD_EXTERNAL_USER_DASHBOARDS](
    context: ActionContext<UserDashboardsState, States>,
    user: {
      data: { [key: string]: {} }
      isOwner: boolean
    }
  ): Promise<{} | void> {
    return http()
      .put(
        `${ENDPOINT_USER_DASHBOARDS}/${user.data.external_user_id}/${context.rootState.condition.dashboard.dashboard_id}`,
        {
          is_owner: user.isOwner,
          status: 'ENABLE',
          last_viewed_at: new DateUtils().format()
        }
      )
      .then((): Promise<{}> => context.dispatch(GET_USER_DASHBOARDS, true))
      .catch((err): void =>
        context.commit(SET_MESSAGE, { text: handleError(err) })
      )
  },
  [UPDATE_USER_DASHBOARDS](
    context: ActionContext<UserDashboardsState, States>,
    userData: { [key: string]: {} }
  ): Promise<void> {
    return http()
      .put(
        `${ENDPOINT_USER_DASHBOARDS}/${userData.userId}/${context.rootState.condition.dashboard.dashboard_id}`,
        {
          is_owner: userData.isOwner,
          status: userData.status,
          last_viewed_at: new DateUtils().format()
        }
      )
      .then((): void => {
        context.dispatch(GET_USER_DASHBOARDS, userData.isExternal)
        context.commit(SET_INTERNAL_USER_ERROR_MESSAGE, [])
      })
      .catch((err): void => {
        context.commit(
          SET_INTERNAL_USER_ERROR_MESSAGE,
          err.response.data.messages
        )
        context.commit(SET_MESSAGE, { text: handleError(err) })
        // TODO: SnackBarに移す案
        // context.dispatch(SET_ERROR, err.response.data.messages.join('\n'))
      })
  },
  [SET_INTERNAL_USER_ERROR_MESSAGE](
    context: ActionContext<UserDashboardsState, States>,
    errorMessage: string[]
  ): void {
    context.commit(SET_INTERNAL_USER_ERROR_MESSAGE, errorMessage)
  }
}

export default actions
