import actions from '@/vuex/actions/user-dashboards'
import getters from '@/vuex/getters/user-dashboards'
import {
  GET_LOGIN_USER_DASHBOARD,
  GET_INTERNAL_USER_DASHBOARDS,
  GET_EXTERNAL_USER_DASHBOARDS,
  SET_INTERNAL_USER_ERROR_MESSAGE
} from '@/vuex/types'
import { MutationTree } from 'vuex'

export interface UserDashboardsState {
  internalUserDashboards: []
  externalUserDashboards: []
  loginUserDashboard: {}
  internalUserErrorMessage: []
}

const initialState: UserDashboardsState = {
  internalUserDashboards: [],
  externalUserDashboards: [],
  loginUserDashboard: {},
  internalUserErrorMessage: []
}

const mutations: MutationTree<UserDashboardsState> = {
  [GET_INTERNAL_USER_DASHBOARDS](
    state: UserDashboardsState,
    userDashboards: []
  ): void {
    state.internalUserDashboards = userDashboards
  },
  [GET_EXTERNAL_USER_DASHBOARDS](
    state: UserDashboardsState,
    userDashboards: []
  ): void {
    state.externalUserDashboards = userDashboards
  },
  [GET_LOGIN_USER_DASHBOARD](
    state: UserDashboardsState,
    loginUserDashboard: { [key: string]: {} }
  ): void {
    state.loginUserDashboard = loginUserDashboard
  },
  [SET_INTERNAL_USER_ERROR_MESSAGE](
    state: UserDashboardsState,
    message: []
  ): void {
    state.internalUserErrorMessage = message
  }
}

export default {
  actions,
  getters,
  mutations,
  state: initialState
}
