import PageLayout from '@/pages/PageLayout.vue'
import Page from '@/pages/Page.vue'
import Setting from '@/pages/Setting.vue'
import TargetSetting from '@/pages/TargetSetting.vue'
import AsyncJobsIntegrationReport from '@/pages/asyncJobs/IntegrationReport.vue'
import AsyncJobsHistory from '@/pages/asyncJobs/History.vue'
import AsyncJobs from '@/pages/asyncJobs/AsyncJobs.vue'
import AsyncJobsRawdata from '@/pages/asyncJobs/Rawdata.vue'
import AsyncJobsClientReport from '@/pages/asyncJobs/ClientReport.vue'
import AsyncJobsAllocationExecute from '@/pages/asyncJobs/AllocationExecute.vue'
import AsyncJobsAllocationResult from '@/pages/asyncJobs/AllocationResult.vue'
import Login from '@/pages/Login.vue'

export default [
  {
    name: 'page-layout',
    path: '/',
    component: PageLayout,
    redirect: '/dashboard',
    children: [
      {
        name: 'page',
        path: 'dashboard',
        component: Page
      },
      {
        name: 'setting',
        path: 'setting',
        component: Setting
      },
      {
        name: 'target_setting',
        path: 'target_setting',
        component: TargetSetting
      },
      {
        path: 'async_jobs',
        component: AsyncJobs,
        children: [
          {
            name: 'async_jobs-history',
            path: '',
            component: AsyncJobsHistory
          },
          {
            name: 'async_jobs-rawdata',
            path: 'rawdata',
            component: AsyncJobsRawdata
          },
          {
            name: 'async_jobs-client_report',
            path: 'client_report',
            component: AsyncJobsClientReport
          },
          {
            name: 'async_jobs-integration_report',
            path: 'integration_report',
            component: AsyncJobsIntegrationReport
          },
          {
            name: 'async_jobs-allocation_execute',
            path: 'allocation_execute',
            component: AsyncJobsAllocationExecute
          },
          {
            name: 'async_jobs-allocation_result',
            path: 'allocation_result/:dashboard_async_job_id',
            component: AsyncJobsAllocationResult
          }
        ]
      }
    ]
  },
  {
    name: 'widgets',
    path: '/widgets/:pagename',
    redirect: '/dashboard'
  },
  {
    name: 'creative',
    path: '/creative',
    redirect: '/dashboard'
  },
  {
    name: 'login',
    path: '/login',
    component: Login
  }
]
