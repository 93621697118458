import { ENDPOINT_DASHBOARD_SETTING } from '@/constants/constant'
import { handleError, http } from '@/utils/http'
import { DashboardSettingState } from '@/vuex/modules/dashboard-setting'
import States from '@/vuex/modules/state'
import { GET_DASHBOARD_SETTING, SET_MESSAGE } from '@/vuex/types'
import { ActionContext, ActionTree } from 'vuex'

const actions: ActionTree<DashboardSettingState, States> = {
  [GET_DASHBOARD_SETTING](
    context: ActionContext<DashboardSettingState, States>
  ): Promise<void> {
    return http()
      .get(ENDPOINT_DASHBOARD_SETTING, {
        params: {
          dashboard_id: context.rootState.condition.dashboard.dashboard_id
        }
      })
      .then((res): void =>
        context.commit(GET_DASHBOARD_SETTING, res.data.pages)
      )
      .catch((err): void =>
        context.commit(SET_MESSAGE, { text: handleError(err.response) })
      )
  }
}

export default actions
