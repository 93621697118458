import type { Ref } from 'vue'

import type { ActionConfigure } from '@/application/action'
import type {
  KpiAllSettingRequest,
  KpiCategorySettingsRequest,
  KpiMediaSettingsRequest
} from '@/domain/model/target/types'

export const fetchKpiAllSetting =
  ({ actionContext }: { actionContext: ActionConfigure }) =>
  (params: Ref<KpiAllSettingRequest>) =>
    actionContext.kpiAllSettingRepository.find(params)

export const updateKpiAllSetting =
  ({ actionContext }: { actionContext: ActionConfigure }) =>
  (params: Ref<KpiAllSettingRequest>) =>
    actionContext.kpiAllSettingRepository.update(params)

export const deleteKpiAllSetting =
  ({ actionContext }: { actionContext: ActionConfigure }) =>
  (params: Ref<KpiAllSettingRequest>) =>
    actionContext.kpiAllSettingRepository.delete(params)

export const fetchKpiMediaSettings =
  ({ actionContext }: { actionContext: ActionConfigure }) =>
  (params: Ref<KpiMediaSettingsRequest>) =>
    actionContext.kpiMediaSettingRepository.findAll(params)

export const updateKpiMediaSettings =
  ({ actionContext }: { actionContext: ActionConfigure }) =>
  (params: Ref<KpiMediaSettingsRequest>) =>
    actionContext.kpiMediaSettingRepository.update(params)

export const deleteKpiMediaSettings =
  ({ actionContext }: { actionContext: ActionConfigure }) =>
  (params: Ref<KpiMediaSettingsRequest>) =>
    actionContext.kpiMediaSettingRepository.delete(params)

export const fetchKpiCategorySettings =
  ({ actionContext }: { actionContext: ActionConfigure }) =>
  (params: Ref<KpiCategorySettingsRequest>) =>
    actionContext.kpiCategorySettingRepository.findAll(params)

export const updateKpiCategorySettings =
  ({ actionContext }: { actionContext: ActionConfigure }) =>
  (params: Ref<KpiCategorySettingsRequest>) =>
    actionContext.kpiCategorySettingRepository.update(params)

export const deleteKpiCategorySettings =
  ({ actionContext }: { actionContext: ActionConfigure }) =>
  (params: Ref<KpiCategorySettingsRequest>) =>
    actionContext.kpiCategorySettingRepository.delete(params)
