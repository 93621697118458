import { ENDPOINT_FILTERS } from '@/constants/constant'
import { handleError, http } from '@/utils/http'
import { FiltersState, FilterAPIRequest } from '@/vuex/modules/filters'
import States from '@/vuex/modules/state'
import {
  GET_FILTERS,
  POST_FILTERS,
  DELETE_FILTERS,
  SET_MESSAGE
} from '@/vuex/types'
import { ActionContext, ActionTree } from 'vuex'

const actions: ActionTree<FiltersState, States> = {
  [GET_FILTERS](context: ActionContext<FiltersState, States>): Promise<void> {
    return http()
      .get(ENDPOINT_FILTERS, {
        params: {
          dashboard_id: context.rootState.condition.dashboard.dashboard_id
        }
      })
      .then((res): void => context.commit(GET_FILTERS, res.data.filters))
      .catch((err): void =>
        context.commit(SET_MESSAGE, { text: handleError(err.response) })
      )
  },
  [POST_FILTERS](
    context: ActionContext<FiltersState, States>,
    filter: FilterAPIRequest
  ): Promise<void> {
    return http()
      .post(ENDPOINT_FILTERS, filter, {
        params: {
          dashboard_id: context.rootState.condition.dashboard.dashboard_id
        }
      })
      .then((): Promise<void> => context.dispatch(GET_FILTERS))
      .catch((err): void =>
        context.commit(SET_MESSAGE, { text: handleError(err.response) })
      )
  },
  [DELETE_FILTERS](
    context: ActionContext<FiltersState, States>,
    filterId: number
  ): Promise<void> {
    return http()
      .delete(`${ENDPOINT_FILTERS}/${filterId}`)
      .then((): Promise<void> => context.dispatch(GET_FILTERS))
      .catch((err): void =>
        context.commit(SET_MESSAGE, { text: handleError(err.response) })
      )
  }
}

export default actions
